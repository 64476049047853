import React from 'react';
import { SectionOne } from './sections/sectionOne';
import { SectionTwo } from './sections/sectionTwo'; 
import { SectionThree } from './sections/sectionThree';
import { HomeSlider } from './sections/homeSlider';
import { DevelopersSection } from './sections/developersSection';
export function HomePage() {
  return (<main >
  <SectionOne />
  <SectionTwo />
  <HomeSlider />
  <DevelopersSection />
  <SectionThree />
  </main>);
}