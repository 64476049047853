/* eslint-disable @typescript-eslint/no-unused-vars */
import React, {useEffect, useState } from "react"
import SearchIcon from '../../assets/icons/search-icon.svg';
import CaretDown from '../../assets/icons/caret-down.svg';
import {CityProps, CountryProps } from "../../includes/types";
import Country from "../../includes/utils/states.json";
import RatingView from "../Rating";
export interface SearchProps {
    state?:string;
    city?:string;
    query?:string;
    rating?:number;
}
const DeveloperSearhBarComponent = ({onValue}:{onValue:(d:SearchProps)=>void})=>{
const [allState,setAllStates] = useState<CountryProps[]>([]);
const [allCities,setAllCities] = useState<CityProps[]>([]);
const [selectedState,setSelectedState] = useState<string>("");
const [selectedCity,setSelectedCity] = useState<string>("");

const [showState,setShowState] = useState<boolean>(false)
const [showCity,setShowCity] = useState<boolean>(false)
const [rating,setRating] = useState<number>(1);
const [query,setQuery] = useState<string>("")
const [searched,setSearched] = useState<boolean>(false);
const SearchSubmit = ()=>{
const formData:SearchProps = {}
formData.state = selectedState;
formData.city = selectedCity;
formData.query = query;
formData.rating = rating;
onValue(formData);
}
useEffect(()=>{
const cn:CountryProps[] = Country as CountryProps[];
setAllStates(cn);
},[])
return <div className="row" >
<div className="col-12" >
<div 
className="developer-section"
>
<div className="card search-section-container text-center">
<div className="search-section-inner" >
<div className="row p-3">
<div className="col-12">
<div className="input-group mb-3 ">
 <div className="input-search-wrapper">
    <img alt="_" src={SearchIcon}
    style={{width:20}}
    />
  <input
  onChange={(d)=>{
    setQuery(d.target.value);
  }}
  placeholder="Search..."
  type="text" className="form-control input-search" aria-label="Dollar amount (with dot and two decimal places)" />
</div>
</div>
</div>
</div>
<div className="row py-2">
<div className="col-5 " onClick={()=>setShowState(!showState)}  >
  <span className="text-start p-3">{selectedState === ""?"All states":selectedState}</span>
  <div className="dropdownItemCaret" >
    <img alt="_" src={CaretDown}
    style={{width:20}}
    />
 </div>
 {showState && <div className="position-relative" >
 <ul className="dropdown-container" >
    {allState.map((item,i)=><li 
    key={i} >
    <div className=""
    onClick={()=>{
        if(allState)
        {
        setSelectedState(allState[i].name);
        setAllCities(allState[i].cities.map((a,i)=>{
            return {
                id:i,
                name:a
            }
        }))
        setSelectedCity("");
    }
    }}
    >{item.name}</div>
    </li>)}
  </ul>
  </div>}
 </div>
 <div className="col-5 " onClick={()=>{
  if(selectedState !== "")
  {
  setShowCity(!showCity);
  }
 }}  >
  <span className="text-start p-3">{selectedCity === ""?"All cities":selectedCity}</span>
  <div className="dropdownItemCaret" >
    <img alt="_" src={CaretDown}
    style={{width:20}}
    />
 </div>
 {showCity && <div className="position-relative" >
 <ul className="dropdown-container" >
 {allCities.map((item,i)=><li key={i}>
    <div className="dropdown-item" onClick={()=>{
        setSelectedCity(item.name)
    }}>{item.name}</div>
 </li>)}  
  </ul>
  </div>}
 </div>
 <div className="col-2"></div>
</div>
<div className="row py-2">
<div className="col-5 "  >
  <span className="text-start p-3 text-end" >Rating:</span>
 </div>
 <div className="col-5 "  >
  <div style={{width:78,marginLeft:-23}}><RatingView
  value={1}
  onValue={(value)=>{
  setRating(value)
  }}
  /></div>
 </div>
 <div className="col-2 "  ></div>
</div> 
<div className="row">
<div className="p-3 pe-5">
<button
type="button"
onClick={()=>SearchSubmit()}
className="btn blueBtn float-right">Search</button>
</div>
</div>
</div>
</div>
</div>
</div>
</div>
}
export default DeveloperSearhBarComponent;