/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-empty */
import React, { CSSProperties, useState } from "react";
import { SelectField, SubmitButton, TextAreaField } from "../forms/inputField";
import { Formik } from "formik";
import * as y from 'yup';
import { CheckIfUserLoggedin, PostData } from "../../includes/function";
import { ActionProps } from "../../includes/types";
interface ReportViewProps {
style?:CSSProperties | undefined;
onValue:()=>void;
title?:string;
postId:string;
postType:string;
}
const Schema = y.object({
    reason:y.string().required("Reason is required."),
    note:y.string().required("Note is required.").max(100),
  })
export const ReportView = (props:ReportViewProps)=>{
const [show,setShow] = useState<boolean>(false);
const [saveObj,setSaveObj] = useState<ActionProps>();
const [content,setContent] = useState<string>("");
const [reason,setReason] = useState<string>("");
const [loading,setLoading] = useState<boolean>(false);
return <>
<span className='mt-3' style={props.style} >
{CheckIfUserLoggedin().token && <SubmitButton 
submit={()=>{
  const x = localStorage.getItem("action");
          if(x !== null)
          {
            const v:ActionProps[] = JSON.parse(x) as ActionProps[];
            const value:ActionProps | null = v.find((a,i)=>a.action === "issue") as ActionProps | null;
            if(value)
            {
              setContent(String(value?.content));
              setReason(String(value?.reason));
            }
          }
  setShow(true);
}}
 title={props?.title?props?.title:'Report this post'}
    />}
</span>
{show && <div 
  className="modal" tabIndex={-1} style={{zIndex: 9999}}>
  <div className="modal-dialog modal-md modal-dialog-centered" >
    <div className="modal-content " >
      <div className="modal-header">
        <h5 className="modal-title">{props?.title?props?.title:'Report this post'}</h5>
        <button type="button" onClick={()=>setShow(false)} className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div className="modal-body"
      style={{overflow:"hidden"}}
      >
<Formik
initialValues={{
    reason:reason,
    note:content
}}
onSubmit={(values)=>{
    setLoading(true);
    PostData("report_issues",{
      ...values,
      postId:props.postId,
      postType:props.postType
    }).then((res)=>{
        setLoading(false); 
        if(res.status) 
        {
          const x = localStorage.getItem("action");
          if(x !== null)
          {
            const v:ActionProps[] = JSON.parse(x) as ActionProps[];
            const value:ActionProps[] = v.filter((a,i)=>a.action !== "issue")
            localStorage.setItem("action",JSON.stringify(value));
          }
        }
        setShow(false);
    })
  return ;  
}}
validationSchema={Schema}
>
{({handleSubmit,handleChange,setFieldValue,errors,values,touched})=><div >
 <SelectField
 label="Select reason" 
 value={values.reason}
 required
 options={[
    {name:"Misleading",value:""},
    {name:"Scam",value:""},
    {name:"Privacy enfringement",value:""},
    {name:"Copyright issues",value:""}
 ]}
 onValueChange={(d)=>setFieldValue("reason",d.name)}
 error={touched.reason && errors.reason}
 />
 <TextAreaField
  name=""
  label="Note" 
  max={100}
  required
  value={values.note}
  onValueChange={(d:any)=>{
    setFieldValue("note",d.target.value);
    const x:ActionProps = {page:"/"+props.postType+"/"+props.postId,action:"issue",content:d.target.value};
    setSaveObj(x);
    try {
      const obj = localStorage.getItem("action");
      if(obj !== null)
      {
        const v:ActionProps[] = JSON.parse(obj) as ActionProps[];
        const nObj = v.map((a:ActionProps,i:number)=>{
          if(a.action === "comment")
          {
           a = x;
          }
          return a;
        })
        const allObj:ActionProps[] = v.find((a:ActionProps,i:number)=>a.action === "issue")?nObj:[...nObj,v] as ActionProps[]
        setSaveObj(x);
        localStorage.setItem("action",JSON.stringify(allObj));
      }else{
        setSaveObj(x); 
        localStorage.setItem("action",JSON.stringify([x]));
        console.log(obj);
      }
     } catch (error) {
      
     }
  }}
  error={touched.note && errors.note}
 />
 <SubmitButton
 loading={loading}
 title="Submit"
submit={handleSubmit}

/>
</div>}
</Formik>
  </div>
  </div>
  </div>
  </div> }
</>
}