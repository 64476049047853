/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from 'react';
import {NavLink, useNavigate, useSearchParams} from 'react-router-dom';
import { LOCALSTORAGE, ROUTES, ReducerObjs } from '../../includes/constants';
import { InputField, SubmitButton } from '../../components/forms/inputField';
import { Formik } from 'formik';
import * as y from 'yup';
import { PostData } from '../../includes/function';
import { useDispatch } from 'react-redux';
const Schema = y.object({
  email:y.string().matches(/^\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/,"A valid email is required").required("A valid email is required").email("A valid email is required"),
  password:y.string().required("Password is required.")
})

export function LoginScreen() {
  const dispatch = useDispatch()
  const [loading,setLoading] = useState(false);
  const navigate = useNavigate();
  const [searchParams,setSearchParams] = useSearchParams();
  useEffect(()=>{
  localStorage.removeItem(ROUTES.OTP);
  window.scrollTo({behavior:"smooth",top:0})
  },[])

  return (<Formik
    initialValues={{
    email:"",
    password:""
    }}
    onSubmit={async(value)=>{
    const action:string = await localStorage.getItem("action") as string;
    setLoading(true);
    PostData("login",value).then((res)=>{
    setLoading(false);
      if(res.code === "CHANGE_DEVICE")
      {
        localStorage.setItem(ROUTES.OTP,ROUTES.OTP)
        navigate("/"+ROUTES.OTP, { state: {message:res.message,email:value.email} });
        return ;
      }
      if(res.status)
      {
        // alert(JSON.stringify(res.data))
        localStorage.setItem(LOCALSTORAGE.Token,res.data.accessToken)
        dispatch({type:ReducerObjs.UpdateUser,payload:res.data});
        localStorage.setItem(LOCALSTORAGE.UserData,JSON.stringify(res.data))
        navigate(ROUTES.UserDashboard);
      }
    })
    }}
    validationSchema={Schema}
    >
    {({handleSubmit,handleChange,values})=><section  className="p-5 py-2 blueSection">
    <div className='container p-5' >
    <div className='row p-5' >
    <div className='col-lg-3 col-md-1' ></div>
    <div className='col-lg-6 col-md-10 card rounded-5'>
    <div className='row p-5' >
    <h4>User login</h4>
    <InputField 
    max={50}
    type="email"
    label='Email'
    placeholder='name@example.com'
    name='email'
    required
    onValueChange={handleChange("email")}
    value={String(values.email).replace(/[ ]/g,'')}
    />
    <InputField 
    max={50}
    type="password"
    label='Password'
    placeholder='*************'
    name='password'
    required
    onValueChange={handleChange("password")}
    value={String(values.password).replace(/[ ]/g,'')}
    />
    <div className="my-3 text-end">
    <NavLink to={"/"+ROUTES.ForgotPassword}>
      Forgot password
      </NavLink>
    </div>
    <div className="mb-3">
    <SubmitButton
     title='Login'
     fill
     submit={handleSubmit}
     loading={loading}
    />
    </div>
    
    <div className='text-end'>
     <NavLink to={ROUTES.SignUp}>
    I dont have account
    </NavLink>
    </div>
    </div>
    </div>
    <div className='col-lg-3 col-md-1' ></div>
    </div>
    </div>
    </section>}
    </Formik>)
}