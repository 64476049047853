/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useRef, useState } from 'react';
import { Navigate, useLocation} from 'react-router-dom';
import { ROUTES } from '../../includes/constants';
import {  SubmitButton } from '../../components/forms/inputField';
import { Formik } from 'formik';
import * as y from 'yup';
import { PostData } from '../../includes/function';
import OTPInput from 'react-otp-input';
import { InfoIcon } from '../../components/icons';

const Schema = y.object({
  verifytoken:y.string().required("A 4-digit token is required.").min(4,"A 4-digit token is required.").max(4,"A 4-digit token is required.")
})
export function OTPScreen() {
  const [loading,setLoading] = useState(false);
  const limit:number = 30;
  const [timer,setTimer] = useState<number>(limit);
  const [position, setPosition] = useState<{latitude:string;longitude:string}>({ latitude:"0", longitude: "0" });
  const timerRef = useRef<any>();
  const navigate = useLocation();
  const [gotoLogin,setGotoLogin] = useState(false)
const Counter = ()=>{
    if(timer !== limit)
    {
        return ;
    }
    timerRef.current = setInterval(()=>{
        setTimer((timer)=>{
            if(timer <= 0)
            {
             clearInterval(timerRef.current);
             return limit;
            }
            return timer - 1;
        })
    },1000)
}
const ResendCode = ()=>{
    if(timer === limit)
    {
        Counter()
    }
}
const GetLocation = ()=>{
    if ("geolocation" in navigator) {
        navigator.geolocation.getCurrentPosition(function (position) {
          setPosition({
            latitude: String(position.coords.latitude),
            longitude: String(position.coords.longitude),
          });
          alert(JSON.stringify(position))
        });
      } else {
        console.log("Geolocation is not available in your browser.");
      }
}
  useEffect(()=>{
    // Counter();
    // GetLocation()
  },[])

  if(gotoLogin)
  {
    return <Navigate to={ROUTES.Login} />
  }

  return (<Formik
    initialValues={{
    verifytoken:"",
    latitude:"0.0",
    longitude:"0.0",
    email:navigate.state.email
    }}
    onSubmit={(value)=>{
    setLoading(true);
    PostData("device_registration",value).then((res)=>{
    setLoading(false);
      if(res.status)
      {
    //  navigate("/"+ROUTES.UserDashboard)
        setGotoLogin(true);
      }
    })
    }}
    validationSchema={Schema}
    >
    {({handleSubmit,handleChange,isValid,setFieldValue,values})=><section  className="p-5 py-2 blueSection">
    <div className='container d-flex pt-5' >
    <div className='row pt-5' >
    <div className='col-2'></div>
    <div className='col-8'>
    <div className='row pt-5' >
    <h4 className='pt-5'>OTP Verification</h4>
    <div className='row'>
    <div className='col-1'>
    <InfoIcon />
    </div>
    <div className='col-11'>{navigate.state?.message}</div>
    </div>
   <OTPInput 
   onChange={(d)=>{
    setFieldValue("verifytoken",d)
   }}
   value={values.verifytoken}
   inputStyle={{width:80,margin:15,height:60,fontSize:20}}
   renderInput={(props)=><input 
    {...props}
    className='form-control'
    placeholder='*'
   />}
   />
    <div className="mb-3">
    <p className='text-end'><span onClick={ResendCode} className={timer !== limit?'':'timerText_'} >{timer === 30?"Resend code":"Please wait for : "}</span> <span className={'timerText'}>{timer !== limit?timer:""}</span>{timer !== limit?"sec":""}</p>
    </div>
    <div className="mb-3">
    <SubmitButton
     disabled={!isValid}
     title='Verify'
     submit={handleSubmit}
     loading={loading}
    />
     
    </div>
    </div>
    </div>
    <div className='col-2'></div>
    </div>
    </div>
    </section>}
    </Formik>)
}