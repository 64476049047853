import React from 'react'
import ImageGallery from './imageGallery'

const HomeDetailsScreen = () => {
  return (<main className='main'>
  <ImageGallery />
    </main>
  )
}

export default HomeDetailsScreen