/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from 'react'
import { ItemProps } from '../../includes/types'
import { Thumbnail } from '../home/sections/sectionTwo'
import { SearhBarComponent } from '../../components/searchbar'
import MapHandlerComponent from '../../includes/mapHandler'
import { PostData } from '../../includes/function'

const RentsListingScreen = () => {
  const [loading,setLoading] = useState<boolean>(false)
  const [limit,setLimit] = useState<number>(8)
  const [list,setList] = useState<ItemProps[]>(Array.from({length:9}).map((a,i)=>{
      return { }
  }))
const GetLatestRents = ()=>{
  PostData("latesthomes",{
    limit:limit
  },false).then((res)=>{
if(res.status)
{
  setList(res.result)
}
  })
}

useEffect(()=>{
  GetLatestRents()
},[])
  return (<main className='content' style={{minHeight:600}} >
     
      <div className='row' >
      <div className='col-6'  style={{overflow:"hidden"}} >
      <SearhBarComponent 
      onValue={(d:any)=>{

      }}
      />
      <div style={{overflow:"hidden",height:"100%"}}>
      <MapHandlerComponent
        coordinates={[]}
      />
</div>
        </div>
        <div className='col-6' >
        <div className='pageTitle'>Rents</div>
        <div 
className='row'
>
{list.filter((item,i)=>i < 8).map((item,i)=><Thumbnail
{...item}
numberOfItems={6}
/>)}
</div>
      </div> 
      </div>
    </main>
  )
}

export default RentsListingScreen;