import { RefObject, useEffect, useRef, useState } from "react";
import { ResourcesProps } from "../../../includes/types"
import { MoreImagesIcon } from "../../MoreImages/icons";
import "./index.css";
import Dropzone from "react-dropzone";
import { PostData } from "../../../includes/function";
import useHttpHooks from "../../../includes/httpHooks";
import { TrashIcon } from "../../../assets/icons";
import { EditIcon } from "../../icons";

import BaseModal from "../../baseModal";
import ImageEditorComponent from "../../imageEditor";
import { BaseLoader } from "../../../css/spinnner";


interface ImageSliderProp {
    list:ResourcesProps[];
    updateImages:(list:ResourcesProps)=>void;
}
export const ImageSlider = (prop:ImageSliderProp)=>{
    const [listOfImages,setListOfImages]= useState<File[]>([]);
    const [selectedIndex,setSelectedIndex]= useState<number | null>(null);
    const [selectedFile,setSelectedFile]= useState<File | null>(null);
    const {handlePostImageUpload,loading} = useHttpHooks();
    const innerContainer = useRef() as RefObject<HTMLDivElement>;
    const handleScroll = ()=>{
        innerContainer.current?.scrollTo({behavior:"smooth",left: 460 })
    }
    useEffect(()=>{
        innerContainer.current?.addEventListener("scroll",({target})=>{
            console.log(target)
        })
    },[])
    const handleImageSelection = (f:File)=>{
        const im = new Image();
        im.src = URL.createObjectURL(f);
        im.onload = ()=>{
            const imWith = im.width;
            const imHeight = im.height;
            setSelectedFile(f);
        }
    }
    return <>
    <div className="sliderContainer">
    <div className="sliderImage" 
    ref={innerContainer}
    >
    {listOfImages.length === 0?<Dropzone 
      accept={{
        "image/*": ['.jpeg', '.png']
      }
      }
    onDrop={acceptedFiles => {
        handleImageSelection(acceptedFiles[0])
        }}>
    {({getRootProps, getInputProps}) => (<>
    <div className="imageItem text-center"  {...getRootProps()} >
           <input {...getInputProps()} />
           <div className="mt-5 mb-3">Select or Drag and drop image</div> 
            <span className="cursor" >
            <MoreImagesIcon size={40}/>
            </span>
     </div>
     </>)}
     </Dropzone>:listOfImages.map((a,i)=><div 
     className="imageItem" key={i} >
        <img 
          alt={`img${i}`}  
            src={URL.createObjectURL(a)} />
             
     </div>
    )}
    {loading && <div className="loaderIndicator">
        <BaseLoader />
        <div className="text-white fs-6">
            Uploading...
        </div>
    </div>}
    </div>
    {listOfImages.length !== 0?<div className="add-more-icon box-shadow">
        <Dropzone 
        accept={{
            "image/*": ['.jpeg', '.png']
          }
          }
     onDrop={acceptedFiles => {
      handleImageSelection(acceptedFiles[0])
         }}>
       
       {({getRootProps, getInputProps}) => (<div {...getRootProps()}>
     <input {...getInputProps()} />
     <MoreImagesIcon />
     <span className="px-2">{"Add More Images"}</span>
     </div>)}
     </Dropzone>
         </div>:null}
    <div className="slider-indicator box-shadow" >
    {listOfImages.map((a,i)=><div className={i+1 === selectedIndex?"activeDot":"inactiveDot"} key={i} ></div>)}
    </div>
    {listOfImages.length !== 0 &&<div className="slider-icon-wrpper box-shadow">
         <div className="slider-icon">
            <TrashIcon size={20}/>
         </div>
         <div
         onClick={()=>{
            handleScroll();
         }}
         className="slider-icon">
            <EditIcon size={15}/>
         </div>
         </div>}
    </div>
    {selectedFile !== null ?<ImageEditorComponent 
    onClose={()=>setSelectedFile(null)}
    onValue={(editedImage:File)=>{
        const countImages = [...listOfImages,editedImage]
        setListOfImages(countImages);
        setSelectedFile(null);
        setSelectedIndex(countImages.length)
        innerContainer.current?.scrollTo({left:460 * countImages.length,behavior:"smooth"});
    }}
    file={selectedFile}
    />:null}
    </>
}
