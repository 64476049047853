/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { RefObject, useEffect, useRef, useState } from 'react'
import './style.css';
import showerIcon from '../../assets/icons/shower.svg';
import bedIcon from '../../assets/icons/bed.svg';
import distanceIcon from '../../assets/icons/distance.svg';
import { ItemProps, ResourcesProps } from '../../includes/types';
import mapPin from '../../assets/icons/map-pin.svg'
import { Navigate, useLocation, useNavigate } from 'react-router-dom';
import { BaseLoader } from '../../css/spinnner';
import { GetData, PostData } from '../../includes/function';
import { ROUTES, blueColor } from '../../includes/constants';
import { toast } from 'react-toastify';
import { VideoIcon, VirtualIcon } from '../../assets/icons';
import { ReportView } from '../../components/report';
import HeartIcon from '../house/icons/heart';
import PrinterIcon from '../house/icons/print';
import MapHandlerComponent from '../../includes/mapHandler';
const ImagePropertyGallery = () => {
  const [details,setDetails] = useState<ItemProps>({})
  const [loading,setLoading] = useState<boolean>(false);
  const [showVideoModal,setShowVideoModal] = useState<boolean>(false);
  const [showVirtualModal,setShowVirtualModal] = useState<boolean>(false);
  const [liked,setLike] = useState<boolean>(false);
  const [loadingLike,setLoadingLike] = useState<boolean>(false);
  const [selectedIndex,setSelectedIndex] = useState(0);
  
  const [thumbnail,setThumbnail] = useState<string>("")
  const [list,setList] = useState<ResourcesProps[]>([])
  const {pathname} = useLocation();
  const navigate = useNavigate();
  const pCon = useRef<HTMLDivElement>() as RefObject<HTMLDivElement>;
  const GetWishlistItem = (id:string)=>{
    GetData("get_wishlist_item",{
      id:id
    },false).then((res)=>{
      setLike(res.status);
  })
  }
  const GetDetails = ()=>{
    const params = String(pathname).split("/")
    const id = params.at(-1);
    setLoading(true);
    GetData("land_details",{
      id:id
    },false).then((res)=>{
      setLoading(false);
    if(res.status)
    {
      GetWishlistItem(res.data.id)
      setThumbnail(res.data.thumbnail)
      setDetails(res.data)
      setList([{uri:res.data.thumbnail},...res.data.images])
    }else{
      toast.error("Item not found",{
        position:"top-center"
      })
      navigate(ROUTES.LandsScreen)
    }
  })
  }
  const handleWishlist = ()=>{
    if(!liked)
    {
    setLoadingLike(true);
    PostData("save_to_wishlist",{
      propertyId:details.id,
      status:liked?1:0
    }).then((res)=>{
      setLoadingLike(false);
      setLike(!liked);
    })
  }
  }
  const [displayResources,setDisplayResources] = useState<ResourcesProps>()
  useEffect(()=>{
    GetDetails();
    document.body.scrollTop = document.documentElement.scrollTop = 0;
  },[])
if(!pathname || String(pathname).split("/").length !== 3)
{
  return <Navigate to="/" />
}
if(loading)
{
  return <div className='p-5'>
    <span className='small px-2' >Fetching details...</span>
    <BaseLoader />
  </div>
}

  return (<div >
    <div className='imageGalleryContainer'
    style={{backgroundColor:"#ddd"}}
  >
    <div className="position-aboslute top-0" style={{width:"100%",height:"100%"}}>
      <MapHandlerComponent 
      coordinates={[]}
      />
    </div>
    <div className='imageThumbsContainner'>
    {list.map((a,i)=><div key={i} onClick={()=>{
    //  setDisplayResources(a);
     setSelectedIndex(i);
     setThumbnail(String(a.uri));
    }}
     className={`${selectedIndex === i?"imageThumbsActive":"imageThumbs"}`} >
      <img 
      src={a.uri}
      alt='x'
       />
    </div>)}
    </div>
    <div className='imageGalleryBlind p-5 item-details' >
    <div className='row'>
  <div className='col-8'>
 <div className='py-3'>
  <span className='whiteBtnSm'>
    {String(details?.type).toUpperCase()}
  </span>
  {details?.featured && <span className='blueBtnSm'>
    Featured
  </span>}
  {details?.hot && <span className='redBtnSm'>
    Hot
  </span>}
  {details?.trendy && <span className='yellowBtnSm'>
    Trendy
  </span>}
</div>
<div  className={"white-text"}>
<h2><span >{details?.title}</span> 
</h2>
</div>
<div 
className='address white-text' >
<img src={mapPin} 
style={{width:18}}
alt='x'
/> 
{details?.state}, {details?.country} <span className='priceDetails' >
{details?.currency}{details?.price}
</span>

</div>
</div>
<div className='col-4 d-flex pt-5'>
<div  
className={'iconsContainer position-relative'} 
onClick={handleWishlist}
> 
{loadingLike?<BaseLoader 
color={blueColor}
size='small'
/>:<HeartIcon 
color={liked?"red":"white"}
size={22}
/>}
<div className='tips'>Like</div>
</div>
<div  

className='iconsContainer position-relative' >
<PrinterIcon
size={25}
/>
<div className='tips'>Print</div>
</div> 
<div  
className='iconsContainer position-relative' 
onClick={()=>setShowVirtualModal(true)}
>
<VirtualIcon
size={25}
/>
<div className='tips'>Virtual Tour</div>
</div>
<div  
className='iconsContainer position-relative'
onClick={()=>setShowVideoModal(true)}
>
<VideoIcon
color='white'
size={25}
/>
<div className='tips'>Video Tour</div>
</div>
<ReportView
onValue={()=>{
return ;
}}
postId={details.id!}
postType={details.type!}
/>
</div>
   </div>
    </div>

    </div>
    <div className='p-5'>
    <h4>Overview</h4> 
    <div >
{details?.amenities?.filter((a,i)=>i < 4).map((a,i)=>{
  return <span className='amenitiesIconsLarge lightBlue' key={i} >
  {a.title?.includes("shower") && <img src={showerIcon} 
  alt='x'
  style={{width:30}} />}
   {a.title?.includes("bed") && <img src={bedIcon} 
    alt='x'
  style={{width:30}} />}
   {a.title?.includes("distance") && <img src={distanceIcon} 
    alt='x'
  style={{width:30}} />}
   <span className='px-3'>{a.value}</span>
  </span>})}
 </div>
    <div className='py-3'>
    <h4>Description</h4>
    <p>
    {details?.description}
    </p>
  </div>
  <div className='py-3'>
    <h4>Additional Details</h4>
    <p>
    {details?.amenities && <table className='table table-responsive table-bordered'>
    {details?.amenities.map((a,i)=><tr >
        <td className='p-2'>{String(a.title).toUpperCase()}</td>
        <td className='p-2'>{a.value}</td>
        </tr>)}
    </table>}
    </p> 
  </div>
  <div className='py-3'>
    <h4>Property on Map</h4>
    <div className='mapContainer'>
    </div>
  </div>
  <div className='py-3'>
    <h4>Property Attachments</h4>
  </div>
    </div>
    </div>
  )
}

export default ImagePropertyGallery;