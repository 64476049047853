/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from "react";
import './style.css';
import { NextIcon, PreviousIcon } from "./icons";
import { PaginationProps } from "../../includes/types";

const Pagination = (props:PaginationProps)=>{
const [totalPages,setTotalPages] = useState<number>(0)
const [previousPages,setPreviousPages] = useState<number>(0)
const [startAt,setStartAt] = useState<number>(1);
const [currentPage,setcurrentPage] = useState<number>(1);
useEffect(()=>{
   if(props.totalItem){
   setTotalPages(props.totalItem) ;
   setcurrentPage(parseInt(String(props?.numberOfitems)))
   }
},[props.totalItem,props?.numberOfitems])
 return <div className="pagination-container">
{props.showMore?<div onClick={props.showMore} className="pagination-button-wrapper text-center" style={{width:120,justifyContent:"center",cursor:"pointer"}}>
   <span className="blueText fs-7" >View more...</span>
</div>:
 <div className="pagination-button-wrapper">
<button 
onClick={()=>{
   if(startAt !== 1) 
   {
      if(props.onPrev)
      {
      setStartAt(startAt - 1);
      props.onPrev(startAt - 1)
      }
   }
}}
disabled={currentPage === 1}
className={`pagination-button pagination-button-prev ${currentPage === 1?"pagination-inactive":""}`}
>
<span>Previous</span>
</button>
<div className="pagination-seperator" ></div>
<button 
className={`pagination-button pagination-button-middle `}
>
{currentPage} of {props.totalItem}
</button>
<div className="pagination-seperator" ></div>
<button 
onClick={()=>{
    if(props.hasNextPage) 
    {
     if(props.onNext)
     {
     setStartAt(parseInt(String(props?.totalItem)) + 1);
     props.onNext(startAt + 1)
     }
    }
 }}
className={`pagination-button btn pagination-button-next ${!props.hasNextPage?"pagination-inactive":""}`}
disabled={currentPage === totalPages}
>
<span>Next</span>
</button>
</div>}
 </div>
}
export default Pagination;