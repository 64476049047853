/* eslint-disable no-empty-pattern */


import { useSelector } from "react-redux";
import { ReducerAction, ReducersProps, UserDataProps } from "../includes/types";
import { ReducerObjs } from "../includes/constants";

export const userInitialState:UserDataProps = {
    firstName:"",
    lastname:"",
    email:"",
    avatar:"",
    dialCode:"",
    mobile:"",
    address:"",
    id:"",
    user_bussiness_address:"",
    recentPosts:[],
    total_listing:0,
    accountType:"Guest",
    bvnVerified:false
  }

  export default function UpdateUserReducer(state:ReducersProps, action:ReducerAction) {
    return {userData:{...state.userData, ...action.payload}};
 }
 export const UpdateUserInfo= (props:UserDataProps)=>{
  return {type:ReducerObjs.UpdateUser,payload:props}
 }
 export const GetUserInfo = ():UserDataProps=>{
  return useSelector<ReducersProps,ReducersProps["userData"]>((state)=>state.userData);
 }
      
