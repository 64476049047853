import React from 'react'
import ImagePropertyGallery from './imagePropertyGallery'

const PropertyDetailsScreen = () => {
  return (<main className='main'>
  <ImagePropertyGallery
   />
    </main>
  )
}

export default PropertyDetailsScreen