/* eslint-disable jsx-a11y/alt-text */
import React, { useEffect, useState } from "react";
import { ResourcesProps } from "../../includes/types";
import { CheckIcon, CloseIcon } from "../../assets/icons";
import { blueColor } from "../../includes/constants";

const ImagePreview = ({imageList,onClose}:{imageList:ResourcesProps[];onClose:()=>void;})=>{
const [selectedItem,setSelectedItem] = useState("");
useEffect(()=>{
 if(imageList.length !== 0)
 {
    setSelectedItem(imageList[0].uri!) 
 }
},[imageList])
if(selectedItem === "")
{
    return null;
}
return <div className="modal" >
    <div className="row" >
    <div className="col-1" ></div>    
    <div className="col-10 " >
    <div className="card imagePreviewContainer mt-5 mb-5" >
    <div className="imagePreviewContainerInner">
     <img  src={selectedItem} />
     <div className="imagePreviewThumnailContainer" >
    {imageList.map((a,i)=><div className="divWp" >
     <img 
    onClick={()=>{
        setSelectedItem(String(a?.uri))
    }}
    src={a.uri} />
    {selectedItem === a.uri && <span className="check" >
        <CheckIcon />
    </span>}
    </div>)}
    </div>
    </div>
    <span className="close"
    onClick={onClose}
    >
        <CloseIcon color={blueColor} />
    </span>
    </div>    
    </div>    
    <div className="col-1" ></div>    
    </div>
</div>
}
export default ImagePreview;