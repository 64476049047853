import React, { RefObject, useEffect, useRef } from "react";

interface VirtualTourFrameProp {
onClose:()=>void;
id:string;
type:string;
title?:string;
}
export const VirtualTourFrame = (props:VirtualTourFrameProp)=>{
    const thisIframe = useRef(null) as RefObject<HTMLIFrameElement>;
useEffect(()=>{
  thisIframe.current?.addEventListener("load", ev => {
    const new_style_element = document.createElement("style");
    new_style_element.textContent = ".ytp-show-cards-title{ display: none; }"
    thisIframe.current?.contentDocument?.head.appendChild(new_style_element);
});
},[thisIframe])
    return <div className='modal' tabIndex={-1}>
    <div className="modal-dialog modal-xl" >
      <div className="modal-content " >
        <div className="modal-header">
          <h5 className="modal-title">Virtual Tour</h5>
          <button type="button"
          onClick={props.onClose}
          className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div className="modal-body"
        style={{height:"72vh", overflow:"hidden",backgroundColor:"#444",padding:0}}
        >
   <iframe width="100%" height="100%" src="https://www.youtube.com/embed/SZMXXjAHsjE?si=HM1us_9lKsdI9vTn?autoplay=1" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe> 
   </div>
    </div> 
    </div>
    </div>
  }
