import React from 'react';
import '@pqina/pintura/pintura.css';

// Import the editor default configuration
import { getEditorDefaults } from '@pqina/pintura';

// Import the editor component from `react-pintura`
import { PinturaEditor } from '@pqina/react-pintura';
import BaseModal from '../baseModal';
import { RefObject, useEffect, useRef, useState } from 'react';
import "./style.css";
import { SubmitButton } from '../forms/inputField';
import Cropper  from "react-easy-crop";
// get default properties
export interface ImageEditorComponentProp {
onClose:()=>void;
file:File | null;
onValue:(data:File)=>void;
}
interface CropProp {
    x:number;
    y:number;
}

const ImageEditorComponent = (prop:ImageEditorComponentProp)=>{
    const aspectRatios = [
        {value:4/3},
        {value:16/9},
        {value:1/2}
    ]
    const canvasRef = useRef<any>(null);
    const editorWrapper  = useRef() as RefObject<HTMLDivElement>;
    const [imageHeight,setHeight] = useState<number>(0);
    const [imageWidth,setWidth] = useState<number>(0);
    const [aspectRatio,setAspectRatio] = useState<number>(aspectRatios[1].value);
    const [crop,setCrop] = useState<CropProp>({x:0,y:0});
    const [croppedAreaPixel,setCroppedAreaPixel] = useState(null);
    const [brigtness,setBrightness] = useState<number>(0);
    const [saturation,setSaturation] = useState<number>(0);
    const [inversion,setInversion] = useState<number>(0);
    const [zoom,setZoom] = useState<number>(0);
    const handleEditorProcess = (imageState:any) => {
        // setEditorResult(URL.createObjectURL(imageState.dest));
        // editorCanvas.current?.toDataURL()
        console.log(imageState)
        prop.onValue(imageState.dest);
    };
    const handleZoom = (value:number)=>{
        setZoom(value);
    }
    const handleBrightness = (value:number)=>{
        setBrightness(value);
    }
    const handleSaturation = (value:number)=>{
        setSaturation(value);
    }
   
    const handleInversion = (value:number)=>{
        setInversion(value);
    }
    const handleWidth = (value:number)=>{
        setWidth(value);
    }
    const handleHeight = (value:number)=>{
        setHeight(value);
    }

    const handleOnCropChange = (crop:any)=>{
           setCrop(crop);
    }
    useEffect(()=>{
       const w =  parseFloat(String(editorWrapper.current?.clientWidth));
       const h =  parseFloat(String(editorWrapper.current?.clientHeight));
       setWidth(w);
       setHeight(h);
    },[imageHeight,imageWidth])
    if(!prop.file)
    {
        return null;
    }

    const settings = {
        brigtness,
        saturation,
        inversion,
        height:imageHeight,
        width:imageWidth
    }
    console.log(settings);
    return  <BaseModal 
        onClose={prop.onClose}
        type="xl"
        center
        title="Image Editor"
        >
        <div className="editorContainer" >
         <div  className='editorWrapper' >
         <Cropper
         zoom={zoom}
         crop={crop}
         aspect={aspectRatio}
         image={"https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSxlT_-PpQJbt3DsWOZDQYohRy4YF8ck1n8PA&s"}
         onCropChange={handleOnCropChange}
         /> 
         <div className='card mb-3 zoomWrap'>
            <input 
            value={zoom}
            min={0}
            max={2}
            step={0.01}
            onChange={({target})=>{
                handleZoom(parseInt(target.value))
            }} 
            type='range'
             />
            </div>
         </div>
         <div className='editorToolContainer px-3'>
            <div className='card p-2 mb-3'>
            <label >Brightness</label>
            <input 
            value={brigtness} 
            type='range'
            onChange={({target})=>{
                handleBrightness(parseInt(target.value))
            }}
            />
            </div>
            <div className='card p-2 mb-3'>
            <label >Saturation</label>
            <input value={saturation}
            onChange={({target})=>{
                handleSaturation(parseInt(target.value))
            }} type='range' />
            </div>
            <div className='card p-2 mb-3'>
            <label >Inversion</label>
            <input
             value={inversion} 
            onChange={({target})=>{
                handleInversion(parseInt(target.value))
            }} type='range' />
            </div>
          
            <div className='p-2 mb-3'>
            <SubmitButton 
            title='Done'
            fill
            />
         </div>
         </div>
         </div>
         </BaseModal>
}
export default ImageEditorComponent;