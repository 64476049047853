/* eslint-disable no-useless-escape */
/* eslint-disable jsx-a11y/alt-text */
import React, { useEffect, useRef, useState } from "react";
import { PlaceHolderAvatar } from "../../../includes/constants";
import { CheckBoxButton, InputField, RadioButton, SubmitButton } from "../../../components/forms/inputField";
import { Formik, FormikValues } from "formik";
import * as y from "yup";
import { UserDataProps } from "../../../includes/types";
import { PostData } from "../../../includes/function";
import MobileNumberUpdateModal from "../../../components/mobileNumberUpdate";
import { CheckIcon } from "../../../assets/icons";
const schemaDoc = y.object({
  docNumber:y.string().required("Document number is required."),
  docType:y.string().required("Document type is required.")
})
const schema2 = y.object({
  new_password:y.string().required("New password is required."),
  current_password:y.string().required("Current password is required."),
  confirm_password:y.string().required("Confirm password is required.")
})
const DashboardSettings = ()=>{
  const [userData,setUserData] = useState<UserDataProps>({
    firstName:"",
    lastname:"",
    email:"",
    accountType:"Guest"
  })
    const [section,setSection] = useState<number>(0);
    const [loading,setLoading] = useState<boolean>(false);
    const [loadingNIN,setLoadingNIN] = useState<boolean>(false);
    const [showMobileModal,setShowMobileModal] = useState<boolean>(false);
    const VerifyDocument = (v:FormikValues)=>{
      setLoadingNIN(true)
      PostData("verify_document",v,true).then((res)=>{
        setLoadingNIN(false);
      })
    }
useEffect(()=>{
  const u = localStorage.getItem("userData");
  if(u)
  {
    setUserData(JSON.parse(u))
  }
},[])
const thisForm = useRef<any>();
if(userData.accountType === "Agent")
{
  return <div ></div>
}
if(userData.accountType === "Developer")
{
  return <div ></div>
}
return <div className="dashboard">
<nav>
  <div className="nav nav-tabs" id="nav-tab" role="tablist">
    <button onClick={()=>{
        setSection(0)
    }} className={`nav-link ${section === 0?"active":""}`}  data-bs-toggle="tab" data-bs-target="#nav-home" type="button" role="tab"  aria-selected="true">Profile</button>
    <button onClick={()=>{
        setSection(1)
    }} className={`nav-link ${section === 1?"active":""}`}   data-bs-toggle="tab" data-bs-target="#nav-password" type="button" role="tab" aria-selected="false">Change Password</button>
    </div>
</nav>
<div className="tab-content" id="nav-tabContent">
  {section === 0 &&<div className="tab-pane fade show active" role="tabpanel" aria-labelledby="nav-profile-tab">
  <div className="card my-3" >
  <div className="card-body">
 
  <h6>{userData.accountType} Account</h6>
  <Formik 
    initialValues={{
    }}
    onSubmit={(values)=>{
      return ;
    }}
    >
{({values,touched,errors,handleChange,handleSubmit})=><div >
<div className="row">
<div className="col-lg-9">
  <InputField 
  max={30}
value={userData.firstName}
onValueChange={()=>{
  return ;
}}
name="firstName"
label="First Name"
placeholder=" First name"
type="text"
required
disabled={true}
/>
<div className="mt-3" >
<InputField 
max={30}
value={userData.lastname}
onValueChange={()=>{
  return ;
}}
name="lastname"
label="Last Name"
placeholder=" Last name"
type="text"
required
disabled={true}
/>
</div>
<div className="mt-3" >
<InputField 
max={100}
label="Email"
value={userData.email}
onValueChange={()=>{
  return ;
}}
name="email"
placeholder="Email address"
type="email"
required
disabled={true}
/>
</div>
</div>
<div className="col-lg-3">
   <div className="card">
   <div className="card-body lighblue-background">
<img src={PlaceHolderAvatar}
style={{width:"100%",height:160}}
/>
<div className="row mt-3">
<div >
</div>
</div>
<div className="text-end mt-3">
</div>
</div>
   </div>
   </div>
   </div>
</div>}
</Formik>
<ul className="list-group mt-3">
  <li className="list-group-item">
    <label >Notifications</label>
  </li>
  <li className="list-group-item ">
  <CheckBoxButton
      onValue={(v)=>{
      return ;
      }}
      value={0}
      name=""
      description="Newsletter alert"
      hint="Received email alert on every newsletter"
      />
  </li>
  <li className="list-group-item"><CheckBoxButton
      onValue={(v)=>{
      return ;
      }}
      value={0}
      name=""
      description="New post alert"
      hint="Received email alert on every new post"
      /></li>
      <li className="list-group-item"><CheckBoxButton
      onValue={(v)=>{
      return ;
      }}
      value={0}
      name=""
      description="Login alert"
      hint="Received email alert on every login"
      />
      </li>
      <li className="list-group-item"><CheckBoxButton
      onValue={()=>{
      return ;
      }}
      value={0}
      name=""
      description="New post alert"
      hint="Received email alert on every newsletter"
      /></li>
</ul>

<div className="card mt-3 p-3" >
  <label>Verifications</label>
<Formik 
    initialValues={{
      mobile:userData.mobile,
      dialCode:userData.dialCode,
    }}
    onSubmit={(values)=>{
      setLoading(true)
    }}
    validationSchema={{}}
    >
  {({values,touched,errors,handleChange,setFieldValue,handleSubmit})=><div 
  className="alert alert-success mt-3"
  >
 <div className="row" >
  <div className="col-10" >
<InputField 
disabled={true}
max={11}
dialCode={values.dialCode}
label="Mobile phone"
value={values.mobile}
onValueChange={handleChange("mobile")}
name="mobile"
placeholder={userData?.mobile}
type="mobile"
required
onDialCode={(d)=>{ 
return ;
}}
/>
</div>
<div className="col-2" >
<div className="mt-4" >
<SubmitButton 
submit={()=>setShowMobileModal(true)}
title="Change"
loading={loading}
/>
</div>
</div>
</div>
{showMobileModal && <MobileNumberUpdateModal 
onClose={(data)=>{
  setShowMobileModal(false)
}}
onSucess={(d)=>{
// setFieldValue("mobile",d);
setShowMobileModal(false)
}}
/>}
</div>}
</Formik>
<Formik 
    initialValues={{
      docNumber:"",
      docType:"nin" 
    }}
    onSubmit={(values)=>{
      VerifyDocument(values)
    }}
    validationSchema={schemaDoc}
    >
{({values,touched,errors,handleChange,handleSubmit,setFieldValue})=><div 
className="alert alert-success"
>
{!userData?.bvnVerified && <div className="row" >
  <div className="col-10" >
    <label >Document type</label>
    <RadioButton
    name="docType"
    value={values.docType}
    onValue={(d)=>{
      setFieldValue("docType",d);
      setFieldValue("docNumber","");
    }}
    list={[
      {name:"NIN",value:"nin"},
      {name:"BVN",value:"bvn"},
      {name:"International Passport",value:"passport"},
      {name:"Driver's license",value:"license"}
    ]}
    error={touched.docType && errors.docType}
    />
  </div>
</div>}
<div className="row" >
<div className="col-10" >
<InputField 
disabled={userData?.bvnVerified || loadingNIN}
max={11}
label="Document Number"
value={userData?.bvnVerified?"XXXX-XXXX-XX":String(values.docNumber).replace(/[a-z A-Z|-]/g,'').replace(/[~`!@#$%^&*()+={}\[\];:\'\"<>.,\/\\\?-_]/g, '')}
onValueChange={handleChange("docNumber")}
name="nin"
required
placeholder="1234567890"
type={userData?.bvnVerified?"text":"password"}
error={touched.docNumber && errors.docNumber}
/>
</div>
<div className="col-2" >
<div className="mt-4" >
{!userData?.bvnVerified? <SubmitButton 
submit={handleSubmit}
title={String(values.docNumber).length === 11?"Verify now":"Verify"}
loading={loadingNIN}
/>:<span className="d-flex justify-content-center align-items-center">
  Verified
  <CheckIcon />
  </span>}
</div>
</div>
</div>
</div>}
</Formik>
   </div>
   </div>
  
</div>
  </div>}
  {section === 1 &&<div className="tab-pane fade show active" id="nav-password" role="tabpanel" aria-labelledby="nav-password-tab">
  <div className="card my-3" >
  <div className="card-body">
  <div className="row">
  <div className="col-lg-12">
  <Formik 
  innerRef={thisForm}
    initialValues={{
      current_password:"",
      new_password:"",
      confirm_password:""
    }}
    onSubmit={(values)=>{
      setLoading(true);
      PostData("change_password",values).then((res)=>{
        setLoading(false);
        if(res.status)
        {
          thisForm.current.resetForm()
        }
      })
    }}
    validationSchema={schema2}
    >
  {({values,touched,errors,handleChange,handleSubmit})=><div >
    <h4>New password</h4>
  <InputField 
  max={30}
value={values.current_password}
onValueChange={handleChange("current_password")}
name="current_password"
label="Current Password"
placeholder="Enter your current Password"
type="password"
required
error={touched.current_password && errors.current_password}
/>
<div className="mt-3" >
<InputField 
  max={30}
value={values.new_password}
onValueChange={handleChange("new_password")}
name="new_password"
label="New Password"
placeholder="Enter your new Password"
type="password"
required
error={touched.new_password && errors.new_password}
/>
</div>
<div className="mt-3" >
<InputField 
max={100}
label="Confirm Password"
value={values.confirm_password}
onValueChange={handleChange("confirm_password")}
name="confirm_password"
placeholder="Enter confirm password"
type="password"
required
error={touched.confirm_password && errors.confirm_password}
/>
</div>
<div className="mt-3" >
<SubmitButton 
loading={loading}
submit={handleSubmit}
title="Save"

/>
</div>
 </div>}
 </Formik>
</div>
<div className="col-lg-4">
  
</div>
</div>
</div>
</div>   
</div>}
</div>
</div>

}
export default DashboardSettings;