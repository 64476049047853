/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable import/first */
import React, { RefObject, useEffect, useRef, useState } from 'react';
import {NavLink} from 'react-router-dom';
import { ROUTES, RegExps } from '../../includes/constants';
import './style.css';
import { InputField, SubmitButton } from '../../components/forms/inputField';
import { PostData } from '../../includes/function';
import { Formik, FormikProps} from 'formik';
import * as y from 'yup';

const Schema = y.object({
  firstName:y.string().required("First name is required.").min(3), 
  lastname: y.string().required("Last name is required.").min(3),
  email:y.string().matches(/^\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/,"A valid email is required").required("A valid email is required").email("A valid email is required"),
  password:y.string().required("Password is required.").matches(RegExps.password,{ message: "Passwords should contain three of the four character types (upper-case lower-case digits and Symbols)" }),
  confirmPassword:y.string().required("Confirm Password is required.").oneOf([y.ref('password'), ""], 'Passwords must match')
})
import MobileNumberUpdateModal from '../../components/mobileNumberUpdate';

export function SignUpScreen() {
const [showPhoneVerication,setShowPhoneVerication] = useState<boolean>(false);
const [loading,setLoading] = useState(false);
const thisForm = useRef() as RefObject<FormikProps<any>>
const RegisterUser = (values:any)=>{
  setLoading(true)
  PostData("register",{
    firstName:values.firstName,
    lastName:values.lastname,
    email:values.email,
    password:values.confirmPassword,
    mobile:values.mobile,
    dialCode:values.dialCode,
    country:values.country
  }).then((res)=>{
    setLoading(false)
    if(res.status)
    {
      setTimeout(()=>{
      window.location.href = ROUTES.Login;
    },1500)
    }
  })
}
useEffect(()=>{
  window.scrollTo({behavior:"smooth",top:0})
},[])
return (<Formik
innerRef={thisForm}
initialValues={{
firstName:"",
lastname:"",
email:"",
password:"",
confirmPassword:"",
mobile:"",
ocr_data:"",
ocr_skipped:"1",
dialCode:"234",
flag:"",
country:""
}}
onSubmit={(value)=>{
  if(value.mobile === "")
  {
   setShowPhoneVerication(true)
  }else{
  RegisterUser(value);
  }
}}
validationSchema={Schema}
>
{({handleSubmit,setFieldValue,touched,errors,handleChange,values})=><section  className="p-5 py-2 blueSection">
<div className='container d-flex align-items-center ' >
<div className='row p-5 mt-5' >
<div className='col-2'></div>
<div className='col-9 card rounded-5'>
<div className='row p-5' >
<h4>Sign-Up</h4>
<div className='row' >
<div className="col-12" >
<div className="mb-3">
<InputField 
max={30}
type="text"
label='First name'
placeholder='John'
name='firstName'
required
onValueChange={handleChange("firstName")}
value={values.firstName}
error={touched.firstName && errors.firstName}
/>
</div>
<div className="mb-3">
<InputField 
max={30}
type="text"
label='Last name'
placeholder='Paul'
name='lastname'
required
onValueChange={handleChange("lastname")}
value={values.lastname}
error={touched.lastname && errors.lastname}
/>
</div>
{values.mobile !== ""?<div className="mb-3">
<InputField 
max={50}
disabled
type="mobile"
label='Mobile number'
placeholder='080000000000'
name='email'
dialCode={values.dialCode}
flag={values.flag}
required
onValueChange={handleChange("mobile")}
onClick={()=>{
  setShowPhoneVerication(true)
}}
value={String(values.mobile).replace(/[ ]/g,'')}
error={touched.mobile && errors.mobile}
/>
</div>:null}
</div>
</div>
<div className="mb-3">
<InputField 
max={50}
type="email"
label='Email'
placeholder='name@example.com'
name='email'
required
onValueChange={handleChange("email")}
value={String(values.email).replace(/[ ]/g,'')}
error={touched.email && errors.email}
onBlur={()=>{
 
}}
/>
</div>
<div className="mb-3">
<InputField 
max={50}
type="password"
label='Password'
placeholder='*************'
name='password'
required
onValueChange={handleChange("password")}
value={String(values.password).replace(/[ ]/g,'')}
error={touched.password && errors.password}
/>
</div>
<div className="mb-3">
<InputField 
max={50}
type="password"
label='confirm Password'
placeholder='*******'
name='confirmPassword'
required
onValueChange={handleChange("confirmPassword")}
value={String(values.confirmPassword).replace(/[ ]/g,'')}
error={touched.confirmPassword && errors.confirmPassword}
/>
</div>

<div className="mb-3">
<SubmitButton
 title={values.mobile !== ""?'Create account':'Continue'}
 submit={handleSubmit}
 loading={loading}
 fill
/>
</div>
<div className="mb-3">
<NavLink to={ROUTES.Login}>
I have an account 
</NavLink>

</div>
</div>
</div>
<div className='col-2'></div>
</div>
</div>
{showPhoneVerication && <MobileNumberUpdateModal 
onClose={()=>{ 
 
  setShowPhoneVerication(false)
}}
onSucess={(d:{dialCode:string;mobile:string,country:string,flag:string;verifytoken:string})=>{ 
  setFieldValue("mobile",d.mobile);
  setFieldValue("dialCode",d.dialCode);
  setFieldValue("flag",d.flag);
  setFieldValue("country",d.country);
  thisForm.current?.handleSubmit()
  setShowPhoneVerication(false);
}}
noToken={true}
title="Mobile number verification"
/>}

</section>}
</Formik>)
}