// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React, { ReactElement, RefObject, createRef, useEffect, useRef, useState } from 'react';
import { BrowserRouter, Route, Routes, useSearchParams } from 'react-router-dom';
import { ROUTES } from './includes/constants';
import { HomePage } from './pages/home';
import { Header } from './components/header';
import {ErrorPage} from './pages/error';
import HomeDetailsScreen from './pages/house/details';
import LandDetailsScreen from './pages/land/details';
import { SignUpScreen } from './pages/signup';
import { LoginScreen } from './pages/login';
import { Footer } from './components/footer';
import HomesListingScreen from './pages/house';
import LandsListingScreen from './pages/land';
import RentsListingScreen from './pages/rents';
import SalesListingScreen from './pages/sales';
import Gleap from 'gleap';
import UserDashboardScreen from './pages/dashboard/user';
import AdminDashboardScreen from './pages/dashboard/admin';
import Dashboard from './pages/dashboard';
import DashboardAnalytics from './pages/dashboard/Analytics';
import DashboardSettings from './pages/dashboard/settings';
import { ToastContainer } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
import { DeviceVerifcationScreen } from './pages/device_verification';
import FavouriteScreen  from './pages/dashboard/myFavourite';
import { OTPScreen } from './pages/otp';
import { AppContextView } from './components/AppContext';
import { AgentScreen } from './pages/agent';
import SearchScreen from './pages/search';
import DeveloperScreen from './pages/developers';
import DeveloperDetailScreen from './pages/developers/details';
import ChatComponent from './components/chat';
import { ForgotPasswordScreen } from './pages/forgotPassword';
import BaseModal from './components/baseModal';
export const loginBtnRef = createRef() as RefObject<HTMLAnchorElement>;
interface CountDownProps {
  days:string;
  hours:string;
  minutes:string;
  seconds:string;
}
export function App() {
  const [countDown,setCountDown] = useState<CountDownProps>({
    days:"0",
    hours:"0",
    minutes:"0",
    seconds:"0"
  });
  const StartCountDown = ()=>{
    const targetDate = new Date('2024-12-31T00:00:00').getTime();
    const countdownInterval = setInterval(() => {
        const now = new Date().getTime();
        const distance = targetDate - now;

        // Calculate time components
        const days = Math.floor(distance / (1000 * 60 * 60 * 24));
        const hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
        const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
        const seconds = Math.floor((distance % (1000 * 60)) / 1000);
        setCountDown({
          days:String(days),
          hours:String(hours),
          minutes:String(minutes),
          seconds:String(seconds)
        })
        if (distance < 0) {
            clearInterval(countdownInterval);
        }
    }, 1000);
  }
  const [showTimer,setShowTimer] = useState<boolean>(true)
useEffect(()=>{
  StartCountDown();
  const params = new URLSearchParams(window.location.search);
  const page = params.get('hide');    // "1"
  const hide = localStorage.getItem("hide");
 if(page)
 {
  setShowTimer(false);
  localStorage.setItem("hide","x");
 }
 if(hide !== null)
  {
   setShowTimer(false);
  }
},[])

return (<BrowserRouter>
    <AppContextView 
    >
    <Header />
    <Routes>
    <Route 
    path={ROUTES.UserDashboard} element={<Dashboard />}  >
      <Route path={"admin"} element={<AdminDashboardScreen />} />
      <Route path={"user"} element={<UserDashboardScreen />} />
      <Route path={"settings"} element={<DashboardSettings/>} />
      <Route path={"*"} element={<DashboardAnalytics />} />
      <Route path={"favourite"} element={<FavouriteScreen />} />
      <Route path={ROUTES.UserDashboard} element={<DashboardAnalytics />} />
    </Route>
    <Route path={ROUTES.OTP} element={<OTPScreen />} />
    <Route path={ROUTES.SignUp} element={<SignUpScreen />} />
    <Route path={ROUTES.Login} element={<LoginScreen />} />
    <Route path={ROUTES.ForgotPassword} element={<ForgotPasswordScreen />} />
    <Route path={ROUTES.DeviceVerificationToken} element={<DeviceVerifcationScreen />} />
    <Route path={ROUTES.Home} element={<HomePage />} />
    <Route path={ROUTES.Search} element={<SearchScreen />} />
    <Route path={ROUTES.Agent} element={<AgentScreen />} />
    <Route path={ROUTES.HousesScreen} element={<HomesListingScreen />}  />
    <Route path={ROUTES.HouseDetail} element={<HomeDetailsScreen />} />
    <Route path={ROUTES.LandsScreen}element={<LandsListingScreen />}  />
    <Route path={ROUTES.LandDetail} element={<LandDetailsScreen />} />
    <Route path={ROUTES.Rent} element={<RentsListingScreen />} />
    <Route path={ROUTES.Sale} element={<SalesListingScreen />} />
    <Route path={ROUTES.Developers} element={<DeveloperScreen />} />
    <Route path={ROUTES.DeveloperDetail} element={<DeveloperDetailScreen />} />
    <Route path={ROUTES.ErrorPage} element={<ErrorPage />} />
    <Route path={"*"} element={<HomePage />} errorElement={<ErrorPage />} />
    </Routes>
    <Footer />
    </AppContextView>
    <ToastContainer />
  {showTimer && <BaseModal
    title=''
    onClose={()=>{}}
    type='md'
    center
    >
<div className='text-center'>
<div className='b fs-4'>Coming Soon!</div>
        <p className='p px-5'>We're working hard to launch our new website. Stay tuned!</p>
        <div className="text-center d-flex justify-content-center pt-2" >
        <div  className="text-center blueText">Check in few days</div>
        </div>
     <div className="text-center d-flex justify-content-center mt-4 gap-3" >
        <div className="card p-3">
        <div className="fs-3 b" id="days">{countDown.days}</div>
        <div className="day" >Days</div>
        </div>
        <div className="card p-3">
            <div className="fs-3" id="hrs">{countDown.hours}</div>
            <div className="day">Hrs</div>
        </div>
        <div className="card p-3">
            <div className="fs-3" id="mins">{countDown.minutes}</div>
            <div className="day">Mins</div>
        </div>
        <div className="card p-3">
            <div className="fs-3" id="secs">{countDown.seconds}</div>
            <div className="day">Secs</div>
        </div>
    </div>
        <p className='p'>
          <small className=' my-3' style={{fontSize:10}}>Powered by <a href="https://www.marshalsoft.tech?product=luxzry-pro">Marshalsoft</a></small>
        </p>
</div>
    </BaseModal>}
    {/* <GetLocation /> */}
    <ChatComponent />
    </BrowserRouter>);
}

export default App;
