import React from "react";
const AdminDashboard = ()=>{
    return <main >
    <div className="row">
    <div className="col-2">

</div>
<div className="col-8 " style={{minHeight:"100vh"}}>
<h1>Admin Dashboard</h1>
</div>
    </div>
</main>
}
export default AdminDashboard;