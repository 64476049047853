/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from 'react';
import {NavLink, useNavigate, useSearchParams} from 'react-router-dom';
import { ROUTES} from '../../includes/constants';
import { InputField, SubmitButton } from '../../components/forms/inputField';
import { Formik } from 'formik';
import * as y from 'yup';
import { PostData } from '../../includes/function';
import { useDispatch } from 'react-redux';
const Schema = y.object({
  email:y.string().matches(/^\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/,"A valid email is required").required("A valid email is required").email("A valid email is required"),
})

export function ForgotPasswordScreen() {
  const dispatch = useDispatch();
  const [loading,setLoading] = useState(false);
  const navigate = useNavigate();
  useEffect(()=>{
  window.scrollTo({behavior:"smooth",top:0})
  },[])

  return (<Formik
    initialValues={{
    email:"",
    }}
    onSubmit={async(value)=>{
    setLoading(true);
    PostData("forgot_password",value).then((res)=>{
    setLoading(false);
      if(res.status)
      {
        navigate(ROUTES.Login);
      }
    })
    }}
    validationSchema={Schema}
    >
    {({handleSubmit,handleChange,values})=><section  className="p-5 py-2 blueSection">
    <div className='container p-5' >
    <div className='row p-5' >
    <div className='col-lg-3 col-md-1' ></div>
    <div className='col-lg-6 col-md-10  card rounded-5'>
    <div className='row p-5' >
    <h4 className='pb-5'>Forgot password</h4>
    <InputField 
    max={50}
    type="email"
    label='Email'
    placeholder='name@example.com'
    name='email'
    required
    onValueChange={handleChange("email")}
    value={String(values.email).replace(/[ ]/g,'')}
    />
    <div className="my-3">
    <SubmitButton
     title='Continue'
     submit={handleSubmit}
     loading={loading}
    />
    </div>
    <div className='text-end'>
     <NavLink to={ROUTES.Login}>
    I have account
    </NavLink>
    </div>
    </div>
    </div>
    <div className='col-lg-3 col-md-1' ></div>
    </div>
    </div>
    </section>}
    </Formik>)
}