import React, { FormEvent,useState } from 'react';
import {NavLink, Navigate} from 'react-router-dom';
import { ROUTES, blueColor } from '../../includes/constants';
import './style.css';
import { PostData } from '../../includes/function';
import { BaseLoader } from '../../css/spinnner';
import OtpInput from 'react-otp-input';

export function DeviceVerifcationScreen() {
const [loading,setLading] = useState(false);
const [otp, setOtp] = useState('');
const VerifyLogin = (e:FormEvent)=>{
  e.preventDefault();
  console.log(e)
  if(!loading)
  {
  setLading(true);
  const data:string = localStorage.getItem("email")!;
  PostData("verify_token",{
    verifytoken:otp,
    email:data
  }).then((res)=>{
    setLading(false)
  })
}
}
if(!localStorage.getItem("email"))
{
    return <Navigate to={ROUTES.Login} />
}
return (<section className="p-5 py-2 blueSection ">
<form action='/' method='post' onSubmit={VerifyLogin}>
<div className='container d-flex align-items-center ' >
<div className='row' >
<div className='col-2'></div>
<div className='col-8'>
<div className='row p-5' >
<h4>Device registration</h4>
<div className="mb-3">
  <label  className="form-label">Enter 4-digit OTP received in your phone or email</label>
 <center >
  <OtpInput
      value={otp}
      onChange={setOtp}
      numInputs={4}
      renderSeparator={<span>-</span>}
      renderInput={(props) => <input {...props} />}
      inputStyle={{width:50,height:40,margin:10,borderRadius:8,borderWidth:1,borderColor:blueColor}}
    />
    </center>
    </div>

<div className="mb-3">
    <button className='btn blueBtn ' >
    {loading?<BaseLoader
    size="small"
    />:"Verify"}
    </button>
</div>
<div className="mb-3">
<NavLink to={ROUTES.Login} className={"float-right"} >
Login
</NavLink>
</div>
<div className="mb-3">
<NavLink to={ROUTES.SignUp} className={"float-right"}>
    I don't have account
    </NavLink>   
</div>
</div>
</div>
<div className='col-2'></div>
</div>
</div>
</form>
</section>)
}