import React from "react";
import { DisabledButton, SubmitButton } from "../forms/inputField";
interface AlertBoxProps {
  message:string;
  title:string;
  onClose:()=>void;
  onValue:(value:string)=>void;
}
const AlertBox = (props:AlertBoxProps)=>{
    return <div className="modal" tabIndex={-1}>
    <div className="modal-dialog modal-dialog-centered">
      <div className="modal-content">
        <div className="modal-header">
          <h5 className="modal-title">{props.title}</h5>
          <button
          onClick={()=>props.onClose()}
          type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div className="modal-body">
          <p>{props.message}</p>
        </div>
        <div className="modal-footer">
        <DisabledButton 
         title="No"
         submit={()=>props.onClose()}
          />
          <SubmitButton 
          title="Yes"
          submit={()=>props.onValue("yes")}
          />
        </div>
      </div>
    </div>
  </div>
}
export default AlertBox;