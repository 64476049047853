import React from "react";

interface BaseLoadingProps {
    text?:string;
    size?:string;
    color?:string;
}

export const BaseLoader = (props:BaseLoadingProps)=>{
return <span className={`spinner-border ${props.size === "small"?"spinner-border-sm":""}`} role="status"
style={{borderRightColor:props.color?props.color:"white"}}
>
{props.text && <span className="visually-hidden">{props.text}.</span>}
</span>
}