/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-var */
import React, { useEffect } from "react";
import { NavLink, Outlet, useLocation,Navigate } from "react-router-dom";
import { ROUTES, ReducerObjs, SideBarLinks, blueColor } from "../../includes/constants";
import './style.css';
import { useDispatch } from "react-redux";
import { GetUserInfo } from "../../redux/userSlice";
import { Greetings } from "../../includes/function";
import {  ArrowRightIcon } from "../../components/footer/icons";
const Dashboard = ()=>{
const location = useLocation();
const userData = GetUserInfo();
const dispatch = useDispatch()
useEffect(()=>{
  const u:string | null = localStorage.getItem("userData")
  if(u)
  {
    dispatch({type:ReducerObjs.UpdateUser,payload:JSON.parse(u)})
  }
},[])
if(!localStorage.getItem("userData"))
{
  return <Navigate
   to={ROUTES.Home} 
   />
}
return <main >
 <div className="sidebar">
  {SideBarLinks.filter((a,i)=>a.accountTypes.includes(userData?.accountType!)).map((item,i)=>{
  var selected = false;
  var locName = location.pathname;
  if(locName.includes(String(item.route)) && locName.includes(String("/")))
  {
    selected = true;
  }
  return <NavLink key={i} to={String(ROUTES.UserDashboard+item.route)}>
  <span className="pe-2">{item.icon?item.icon(selected?"White":"black"):null}</span>
  {item.title}</NavLink>})}
</div>
<div className="content px-5" style={{minHeight:"100vh"}}>
  <div className="my-3 row">
  <div className="col-7 blueText" >
  <span className="blueText px-3 btn">Dashboard</span><ArrowRightIcon color={blueColor} size={18} /><span className="ps-3"></span> {String(userData.accountType)} Account
  </div>
  <div className="col-5 justify-content-end" >
  <span className="float-end">{Greetings()} {userData.firstName}.</span>
  </div>
  </div>
<Outlet
/>
</div>
</main>
}

export default Dashboard;