import React, { createContext, ReactNode, useEffect, useState } from "react";
import { GetData} from "../../includes/function";
import { LOCALSTORAGE } from "../../includes/constants";
interface AppContextProps {
    userDetails?:{
        firstName:string;
        lastname:string;
        email:string;
        mobile:string;
    }
}
export const AppContextModel:AppContextProps = {
}

const AppContext = createContext(AppContextModel);
export const AppContextView = ({children}:{children:ReactNode})=>{
   const [context,setContext] = useState<AppContextProps>({})
   const GetUserDetails = ()=>{
    GetData("current_user",{},false).then((res)=>{
        if(res.status)
        {
         setContext({userDetails:res.result})
        }
    })
   }
   useEffect(()=>{
    GetUserDetails();
   },[])
    return <AppContext.Provider 
    value={context}
    >
   <AppContext.Consumer >
    {context=>children}
    </AppContext.Consumer>
    </AppContext.Provider>
}