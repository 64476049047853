/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-useless-escape */
import React, { useEffect, useState } from 'react';
import {NavLink, Navigate, useLocation, useSearchParams} from 'react-router-dom';
import { ROUTES } from '../../includes/constants';
import { PostData } from '../../includes/function';
import { ItemProps, UserDataProps } from '../../includes/types';

import { Thumbnail } from '../home/sections/sectionTwo';
import { BookATour } from '../house/imageGallery';
import { BaseLoader } from '../../css/spinnner';
import Pagination from '../../components/pagination';

export function AgentScreen() {
  const [fetching,setFetching] = useState<string>("");
  const {pathname} = useLocation();
  const [houseList,setHouseList] = useState<ItemProps[]>([]);
  const [landList,setLandList] = useState<ItemProps[]>([]);
  const [loading,setLoading] = useState(false);
  const [landStartLimit,setStartLandLimit] = useState(0);
  const [landEndLimit,setEndLandLimit] = useState(20);
  const [homeStartLimit,setStartHomeLimit] = useState(0);
  const [homeEndLimit,setEndHomeLimit] = useState(20);
  const [postType,setPostType] = useState<string | null>("house");
  const [agentObject,setAgentObject] = useState<UserDataProps | undefined>()
  const [searchParams, setSearchParams] = useSearchParams();
  const GetHostDetails = (agentId:string)=>{
    PostData("get_host_details",{
      userID:agentId
    },false).then((res)=>{
      if(res.status)
      {
        setFetching("done");
        setAgentObject(res.result)
      }else{
        setFetching("redirect");
      }
    })
 }

 const GetLandList = (id:string)=>{
  PostData("get_agent_land_properties",{
    userId:id,
    limit:`${landStartLimit},${landEndLimit}`
  },false).then((res)=>{
    if(res.status)
    {
      setLandList(res.result);
    }
  })
 }
 const GetHouseList = (id:string)=>{
  PostData("get_agent_house_properties",{
    userId:id,
    limit:`${homeStartLimit},${homeEndLimit}`
  },false).then((res)=>{
    if(res.status)
    {
      setHouseList(res.result);
    }
  })
 }

  useEffect(()=>{
  const filterId = String(pathname).replace(/[a-z\/]/g,'');
  if(!isNaN(parseInt(filterId)))
  {
    GetHostDetails(filterId);
    GetHouseList(filterId);
    GetLandList(filterId);
    setPostType("home");
  }
    if(searchParams)
    {
      const params = searchParams.get("q");
      if(params && ["house","land"].includes(params))
      {
        setPostType(params);
      }
    }
    },[searchParams,pathname])
  if(fetching === "")
  {
    return <div >
      <BaseLoader />
    </div>
  }
  if(fetching === "redirect")
  {
    return <Navigate to={ROUTES.ErrorPage} />
  }
  return (<main className='content' style={{minHeight:600}} >
  <div className='row p-5' >
<div className='col-8' >
<div className='pageTitle'>My Listing <span className='px-3'><NavLink to={`/agent/${agentObject?.id}?q=house`} className={"light-blue"} style={{color:postType === "house"?"white":""}}>Houses({houseList.length})</NavLink></span>|<span className='px-3' ><NavLink to={`/agent/${agentObject?.id}?q=land`} className={"light-blue"}  style={{color:postType === "land"?"white":""}}>Lands({landList.length})</NavLink></span> </div>
{postType === "house" && <div >
  <div 
className='row'
>
{houseList.filter((item,i)=>i < 8).map((item,i)=><Thumbnail
{...item}
key={i}
numberOfItems={6}
/>)}
</div>
<Pagination />
</div>}
{postType === "land" && <div >
  <div 
className='row'
>
{landList.filter((item,i)=>i < 8).map((item,i)=><Thumbnail
{...item}
numberOfItems={6}
/>)}
</div>
<Pagination />
</div>}
  </div> 
  <div className='col-4 tourSection' style={{overflow:"hidden"}} >
  {agentObject?.id && <BookATour 
  agentId={agentObject?.id}
  profile={true}
  />}
  </div>
  </div>
</main>
)
}