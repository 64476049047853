import React from "react";
interface BaseModalProps {
onClose:(d:any)=>void;
children:JSX.Element;
title?:string;
type:"lg"|"sm"|"md"|"xl";
center?:boolean;
}
const BaseModal = (props:BaseModalProps)=>{
    return <div 
    className="modal" tabIndex={-1}>
    <div className={`modal-dialog modal-${props.type} ${props?.center?"modal-dialog-centered":""}` } >
      <div className="modal-content " >
        <div className="modal-header">
          <h5 className="modal-title">{props?.title}</h5>
          <button 
          onClick={()=>props.onClose(null)}
          type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div className="modal-body"
        style={{minHeight:30}}
        >
        {props.children}
        </div>
        </div>
        </div>
        </div>
}
export default BaseModal;