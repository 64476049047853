/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable @typescript-eslint/no-empty-function */
import React, {ChangeEventHandler, FocusEventHandler, RefObject, useEffect, useRef, useState } from "react"
import { Field} from 'formik';
import { BaseLoader } from "../../../css/spinnner";
import { BlockIcon, EyeCloseIcon, EyeIcon } from "../../icons";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { CountrylistModel, ItemModel,SubItemModel } from "../../../includes/types";
import { ArrowDownIcon } from "../../footer/icons";
import OTPInput from 'react-otp-input';
import Countrylist from "../../../includes/countries.json";
import { WhatsappIcon } from "../../share/icons";
import { SearchIcon } from "../../../assets/icons";

interface InputFieldProps {
    onDialCode?:(obj:CountrylistModel)=>void;
    leftIcon?:JSX.Element | string;
    rightIcon?:JSX.Element | string;
    label?:string;
    disabled?:boolean;
    placeholder?:string;
    name:string;
    required?:boolean;
    value:any;
    type:"text"|"email"|"number"|"mobile"|"password"|"address"|"otp";
    min?:number;
    max?:number;
    pattern?:string;
    onValueChange?:ChangeEventHandler<HTMLInputElement>;
    options?:{value:string;name:string;}[];
    error?:any;
    SendToWhatsApp?:(v:boolean)=>void;
    onClick?:()=>void;
    dialCode?:string;
    flag?:string;
    onBlur?:FocusEventHandler<HTMLInputElement> | undefined
}
interface SelectFieldProps {
  leftIcon?:JSX.Element | string;
  rightIcon?:JSX.Element | string;
  label?:string;
  disabled?:boolean;
  placeholder?:string;
  value:string;
  required?:boolean;
  onValueChange:(d:ItemModel)=>void ;
  options?:ItemModel[] | SubItemModel[] | any[];
  error?:any;
}
interface TextAreaFieldProps {
  label?:string;
  disabled?:boolean;
  placeholder?:string;
  name:string;
  required?:boolean;
  value:any;
  max?:number;
  rows?:number;
  pattern?:string;
  onValueChange:ChangeEventHandler<HTMLTextAreaElement> | any;
  error?:any;
  richtext?:boolean;
  editorState?:any;
 
}
interface SubmitButtonProps {
title:string;
loading?:boolean;
submit?:any;
disabled?:boolean;
left?:boolean;
children?:JSX.Element;
icon?:JSX.Element;
fill?:boolean;
}
interface CheckBoxButtonProps {
label?:string;
name:string;
description?:string;
onValue:(value:boolean)=>void;
value:number;
icon?:string;
hint?:string;
}
interface RadioButtonProps {
  list:ItemModel[];
  label?:string;
  onValue:(v:string)=>void;
  name:string;
  value?:string;
  error?:any;
 
}
export const InputField = (props:InputFieldProps)=>{
  const [searchText,setSearchText] = useState<string>("")
  const [toggleEye,setToggleEye] = useState<boolean>(true)
  const [sendToWhatsapp,setSendToWhatsapp] = useState<boolean>(false)
  const [targetValue,setTargetValue] = useState<string>("")
  const [showList,setShowList] = useState<boolean>(true)
  const [showCountryList,setShowCountryList] = useState<boolean>(false);
  const [list,setList] = useState<ItemModel[]>([])
  const [countries,SetCountrylist] = useState<CountrylistModel[]>([]);
  const [selectedCountry,setSelectedCountry] = useState<CountrylistModel>({
    calling_code:"234",
    flag:"iVBORw0KGgoAAAANSUhEUgAAAB4AAAAUCAIAAAAVyRqTAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyRpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuMy1jMDExIDY2LjE0NTY2MSwgMjAxMi8wMi8wNi0xNDo1NjoyNyAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENTNiAoTWFjaW50b3NoKSIgeG1wTU06SW5zdGFuY2VJRD0ieG1wLmlpZDo0RjI4OEFDMTE3ODQxMUUyQTcxNDlDNEFCRkNENzc2NiIgeG1wTU06RG9jdW1lbnRJRD0ieG1wLmRpZDo0RjI4OEFDMjE3ODQxMUUyQTcxNDlDNEFCRkNENzc2NiI+IDx4bXBNTTpEZXJpdmVkRnJvbSBzdFJlZjppbnN0YW5jZUlEPSJ4bXAuaWlkOjRGMjg4QUJGMTc4NDExRTJBNzE0OUM0QUJGQ0Q3NzY2IiBzdFJlZjpkb2N1bWVudElEPSJ4bXAuZGlkOjRGMjg4QUMwMTc4NDExRTJBNzE0OUM0QUJGQ0Q3NzY2Ii8+IDwvcmRmOkRlc2NyaXB0aW9uPiA8L3JkZjpSREY+IDwveDp4bXBtZXRhPiA8P3hwYWNrZXQgZW5kPSJyIj8+qCpo0QAAADBJREFUeNpiZGgPZMAN/leswyPL2BGER5aJgWZg1OhRo0eNHjV61OhRo2lnNECAAQBu1gQALTkVbAAAAABJRU5ErkJggg=="
    }
)

  const thisCountryList = useRef() as RefObject<HTMLDivElement>
  const returnCountry = ():CountrylistModel[]=>{
    const cn:CountrylistModel[] = [];
    Countrylist.forEach((a:any)=>{
      cn.push(a)
    })
    return cn; 
 }
 useEffect(()=>{
    SetCountrylist(returnCountry())
    if(props.type === "mobile" && props.onDialCode)
  {
    props.onDialCode(selectedCountry)
  }
 },[])
 
 useEffect(()=>{
  thisCountryList.current?.addEventListener("mouseleave",()=>{
    setShowCountryList(false);
    setSearchText("");
  })
 },[showCountryList])
 useEffect(()=>{
if(props.dialCode)
{
  setSelectedCountry({
    ...selectedCountry,
    flag:props.flag,
    calling_code:props.dialCode
  })
}
 },[props.dialCode])

  const OnTyping = (e:any)=>{
    const value = e.target.value;
    setTargetValue(value)
    console.log(value)
  }
  const AllData = countries.filter((a,i)=>String(a.name).toLowerCase().includes(String(searchText).toLowerCase()) )
  if(props.type === "otp")
  {
    return <div className="mb-0">
    <div 
    className="position-relative" >
    {props?.label && <label  htmlFor={props.name} className="form-label">{props.label}</label>}
    <div className={"otp d-flex align-items-center justify-content-center"} >
    <OTPInput 
   onChange={(d:any)=>{
    if(props.onValueChange)
    {
      props.onValueChange(d)
    }
   }}
   value={props.value}
   numInputs={props.max?props.max > 6?4:props.max:4}
   inputStyle={{width:80,margin:15,height:60,fontSize:20}}
   renderInput={(props)=><input 
    {...props}
    className='form-control'
    placeholder='*'
   />}
   />
    </div>
    </div>
    </div>
  }
  if(props.type === "mobile" && props.disabled)
  {
    return <div className="mb-0">
    <div 
    className="position-relative" >
    {props?.label && <label  htmlFor={props.name} className="form-label">{props.label}</label>}
    <div className={"left-icon-wrap"}  
     onClick={()=>{
      if(props.onClick)
      {
        props.onClick()
      }
     }}
    >
    <div className="flagContainer"
    
    >
      <img
      src={"data:image/png;base64,"+selectedCountry.flag}
      style={{objectFit:"fill"}}
      alt="x"
      />
      <span className="px-2">+{selectedCountry.calling_code}</span>
    </div>
    <Field 
    type={props.type}
    name={props.name} 
    disabled={true}
    required={props?.required} 
    className="form-control baseFormControl roundInput"  
    placeholder={props.placeholder}
    value={String(parseInt(props.value)).replace("NaN","")}
    maxLength={props.max}
    min={props.min}
     />
     <span className="blockIcon">
      <small className="mx-2 blueText">Change number</small>
      <BlockIcon 
      size={15}
      color="red"
      />
    </span>
   <div
   style={{position:"absolute",top:25,height:40,left:0,width:"100%",cursor:props.onClick?"pointer":"none"}}></div>
  </div>
{props.error?<div className="error" >{props.error}</div>:null}
  </div>
  </div>
  }
  return <div className="mb-0">
    <div 
    className="position-relative" >
    {props?.label && <label  htmlFor={props.name} className="form-label">{props.label}</label>}
    <div className={props.leftIcon || props.type === "mobile"?`left-icon-wrap `:""}  >
    {props.type === "mobile"?<div className="flagContainer"
    onClick={()=>{
     setShowCountryList(!showCountryList);
    }}
    >
      <img
      src={"data:image/png;base64,"+selectedCountry.flag}
      style={{objectFit:"fill"}}
      alt="x"
      />
      <span className="px-2">+{selectedCountry.calling_code}</span>
    </div>:props.leftIcon?<span  className="left-icon">{props.leftIcon}</span>:null}
    <input 
    type={props.type === "password"?toggleEye?"password":"text":props.type}
    name={props.name} 
    disabled={props.disabled}
    required={props?.required} 
    className="form-control baseFormControl roundInput"  
    placeholder={props.placeholder}
    value={props.type === "address"?targetValue:props.type === "mobile"?String(parseInt(props.value)).replace("NaN",""):props.value}
    onChange={props.type === "address"?OnTyping:props.onValueChange}
    maxLength={props.max}
    min={props.min}
    pattern={props.pattern}
    onBlur={props.onBlur}
     />
     {props.disabled?<span className="blockIcon">
      <BlockIcon 
      size={15}
      color="red"
      />
    </span>:props.type === "mobile"?<div 
    className="whatsapp-icon">
  Send to Whatsapp
  <div className="whatsapp-checker"
  onClick={()=>{
    setSendToWhatsapp(!sendToWhatsapp)
    if(props.SendToWhatsApp)
    {
    props.SendToWhatsApp(!sendToWhatsapp)
    }
  }}
  >
  {sendToWhatsapp && <WhatsappIcon 
  size={20}
  />}
  </div>
</div>:props.type === "password"?<span className="eyeIcon" style={{top:props.label?30:7}} onClick={()=>setToggleEye(!toggleEye)} >{toggleEye?<EyeIcon color="black" size={20} />:<EyeCloseIcon color="black" size={20} />}</span>:props.rightIcon?<span  className="right-icon">{props.rightIcon}</span>:null}
  </div>
  {props.type === "mobile" && showCountryList?<div 
  ref={thisCountryList}
  style={{top:65,left:15,overflow:"hidden",maxHeight:400}} className="dropdown-container">
  <div className="mx-3 form-control baseFormControl roundInput d-flex justify-content-start" style={{width:"auto",paddingLeft:5}}>
      <SearchIcon 
      size={25}
      />
      <input
      onChange={(d)=>{
        setSearchText(d.target.value)
      }}
      placeholder="Search..." maxLength={20} className=""   />
    </div>
    <div className="" style={{height:350,overflow:"hidden"}}>
    <ul 
  className=""
  style={{overflow:"scroll",height:340,listStyle:"none",padding:0}}
  >
  {AllData.map((item,i)=><li 
        key={i} >
        <div className="dropdown-item"
        onClick={()=>{
            setSelectedCountry(item);
            if(props.type === "mobile" && props.onDialCode)
            {
              props.onDialCode(item)
            }
            setShowCountryList(false);
        }}
        >{item.name}</div>
        </li>)}
      </ul>
      </div>
      </div>:null}
  </div>
{props.error?<div className="error" >{props.error}</div>:null}
{showList && props.type === "address"?<div className="position-relative" >
 <ul className="dropdown-container" style={{left:10,top:-33,width:"80%"}} >
    {list.map((item,i)=><li 
    key={i} >
    <div className=""
    onClick={()=>{
     
    }}
    >{item.name}</div>
    </li>)}
  </ul>
  </div>:null}
  </div>
}
export const TextAreaField = (props:TextAreaFieldProps)=>{
 return <div className="mb-3 row">
   <div 
   className="position-relative" >
   {props?.label && <label  htmlFor={props.name} className="form-label">{props.label}</label>}
   {props.richtext?<div >
    <ReactQuill 
      theme="snow" 
      value={props.value} 
      onChange={props.onValueChange} 
      className="roundTextarea"
      />
   </div>:<textarea 
   name={props.name} 
   disabled={props.disabled}
   required={props?.required} 
   rows={props?.rows?props?.rows:4}
   className="roundTextarea" 
   style={{borderRadius:8,padding:10}} 
   placeholder={props.placeholder}
   value={props.value}
   onChange={props.onValueChange}
   maxLength={props.max}
    />}
  </div>
<div style={{marginTop:-7}}>
{props.error?<span className="error" style={{marginTop:0}}>{props.error}</span>:null}
{props.max?<small style={{fontSize:"0.7rem",float:"right"}} className='text-positive'>{props.value.length} of {props.max}</small>:null}
</div>
 </div>
}
export const SelectField = (props:SelectFieldProps)=>{
const [show,setShow] = useState<boolean>(false)
const thisElement = useRef<HTMLUListElement>(null);
useEffect(()=>{
  thisElement.current?.addEventListener("mouseleave",()=>{
    setShow(false)
  })
},[show])
    return <div 
    className="mb-3" >
    {props?.label && <label  className="form-label">{props.label}</label>}
    <div className="roundInput m-0 position-relative"
    onClick={()=>setShow(!show)}
    >
    <div 
    className="fieldText"
    style={{color:props.value?"black":"#ccc"}}
     >
     {props.value?props.value:props.placeholder?props.placeholder:"Select an item"}
     </div>
     <span className="right-icon">
      <ArrowDownIcon
      size={30}
      />
     </span>
  </div>
  <div className="position-relative"
   onClick={()=>setShow(!show)} >
  {props.error?<div className="error" >{props.error}</div>:null}
 {show && <ul className="dropdown-container" 
 ref={thisElement}
 style={{zIndex:999999999,right:20}}>
    {props.options?.map((item,i)=><li 
    key={i} >
    <div className=""
    onClick={()=>{
     props.onValueChange(item)
    }}
    >{item.name}</div>
    </li>)}
  </ul>}
  </div>
  </div>
}
export const SubmitButton = (props:SubmitButtonProps)=>{
    useEffect(()=>{

    },[])
   
    return <div 
    className="mb-3" >
    <button
    disabled={props?.disabled || props.loading}
    type="submit"
    onClick={props.submit}
    className={`btn  ${props?.disabled?"disableBtn":"blueBtn"} ${props?.left?"float-left":"float-right"} ${props?.fill?"btn-fill":""}`}
    >
    {props.loading?<BaseLoader
    size="small"
    />:<>
    {props?.icon?props?.icon:null}
    <span>{props.title}{props.children}</span></>}
    </button>
  </div>
}
export const CheckBoxButton = (props:CheckBoxButtonProps)=>{
const [value,setValue] = useState<boolean>(false)
  return <div 
    className="mb-3" >
    {props?.label && <label  htmlFor={props.name} className="form-label">{props.label}</label>}
    <div className="form-check">
  <input 
  className="form-check-input" type="checkbox" value={props.value?1:0} id={props.name}
  onChange={()=>{
    setValue(!value);
    props.onValue(!value);
  }}
  />
  {props?.icon?<img
  src={props.icon}
  style={{width:20,marginTop:10,marginLeft:10}}
  />:null}
  <label className="form-check-label" htmlFor={props.name}>
    {props.description}
    {props.hint?<br/>:null}
    <small className="form-check-label-small">{props.hint?props.hint:null}</small>
  </label>
</div>  
    </div>
}
export const RadioButton = (props:RadioButtonProps)=>{
  const [value,setValue] = useState<boolean>(false);
    return <div >
      <div className="d-flex align-items-center ">
    {props.list.map((a,i)=><div className="form-check check-box">
  <input className="form-check-input" type="radio" onChange={(d)=>{
  props.onValue(String(a.value))
  }} 
  checked={String(props.value) === a.value}
  name={props.name} />
  <label className="form-check-label" htmlFor="flexCheckDefault">
    {a.name}
  </label>
    </div>)}
      </div>
      {props.error?<div className="error" >{props.error}</div>:null}
      </div>
  }
export const DisabledButton = (props:SubmitButtonProps)=>{

  useEffect(()=>{

  },[])

  return <div 
  className="mb-3" >
  <button
  disabled={props?.disabled}
  type="submit"
  onClick={props.submit}
  className={`btn inactiveBtn ${props?.left?"float-left":"float-right"}`}
  >
  {props.title}{props.children}
  </button>
</div>
}
