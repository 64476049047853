/* eslint-disable @typescript-eslint/no-empty-function */
import React, { RefObject, useEffect, useRef, useState } from "react";
import { ItemModel } from "../../includes/types";
import { FilterIcon } from "../../assets/icons";
import './style.css';
interface FilterComponentProps {
    onValue?:(value:ItemModel)=>void;
    position?:"left"|"right";
    list?:ItemModel[]
}
const FilterComponent = (props:FilterComponentProps)=>{
const [show,setShow] = useState<boolean>(false);
const [selectedFilter,setSelectedFilter] = useState<string>("Filter");
const thisDiv = useRef(null) as RefObject<HTMLDivElement>;
useEffect(()=>{
if(thisDiv.current)
{
    thisDiv.current.addEventListener("mouseleave",()=>{
        setShow(false);
    }) 
}
return ()=>{

}
},[show])
return <div className="filter-wrp"
    ref={thisDiv}
    >
        <div 
onClick={()=>setShow(!show)}
className="filter-container"
>
<span className="fs-7">{selectedFilter}</span><FilterIcon />
</div>
<div 
className="position-relative"
>
 {show && props.list?<ul 
 className="dropdown-container"
 style={{width:100,left:0}}
 >
{props.list.map((a,i)=><li 
onClick={()=>{
    if(props.onValue)
    {
    setSelectedFilter(String(a.name))
    props.onValue(a);
    }
    setShow(false);
}}
key={i} className="dropdown-item fs-7">{a.name}</li>)}
 </ul>:null}  
</div>
</div>
}
export default FilterComponent;