/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable jsx-a11y/alt-text */
import React, { useEffect, useState } from 'react';
import {NavLink} from 'react-router-dom';
import './style.css';
import { ItemProps, ResourcesProps } from '../../../includes/types';
import mapPin from '../../../assets/icons/map-pin.svg';
import { GetData, PostData } from '../../../includes/function';
import ImagePreview from '../../../components/imagePreview';
export function HomeSlider() {
 
  const [selectedImages,setSelectedImages] = useState<ResourcesProps[] | undefined>(undefined)
  const [list,setList] = useState<ItemProps[]>([])
  const [loading,seTLoading] = useState<boolean>(false)
  const GetLatestHouses = ()=>{
    GetData("recent_houses",{
      page:1,
      address:"Nigeria"
    },false).then((res)=>{
  if(res.status)
  {
    setList(res.result);
  }
    })
  }



useEffect(()=>{
  GetLatestHouses();
},[])
if(list.length === 0)
{
  return null;
}
return (<section className="d-flex flex-column p-5 py-2 blueSection" style={{height:550}}>

<div id="carouselExampleIndicators" className="carousel slide" data-bs-ride="carousel">
  {list.map((a,i)=> <div key={i} className={`carousel-item ${i === 0?"active":""}`}>
  <div className='row py-5' style={{width:"100vw"}} >
<div className='col-6 position-relative' >
    <img 
    src={String(a.thumbnail)}
    className='homeSliderImg' />
    <span className='priceLarge' >
        {a?.currency}{a?.price}
    </span>
    <div className='images'>
      {a.images?.filter((a,i)=>i === 0).map((a,i)=>{
        return  <img
        src={String(a?.uri)}
        />
      })}
      <div className='wpp'
      onClick={()=>{
        setSelectedImages(a?.images)
      }}
      >
      <span style={{fontSize:20}}>+{a?.images?.length}</span>
      </div>
    </div>
</div>  
<div className='col-6 item-details' >
<div className='py-3'>
  <span className='whiteBtnSm'>
    {String(a.type).toUpperCase()}
  </span>
  {/* {details?.featured && <span className='blueBtnSm'>
    Featured
  </span>}
  {details?.hot && <span className='redBtnSm'>
    Hot
  </span>}
  {details?.trendy && <span className='yellowBtnSm'>
    Trendy
  </span>} */}
</div>
    <NavLink to={`/${String(a?.type).toLocaleLowerCase()}/${a?.id}`}>
        <h2>{a?.title}</h2>
    </NavLink>
<div className='address' >
  <img src={mapPin} 
  style={{width:18}}
  /> 
  {a?.state}, {a.country}
  </div>
  <div className='py-3'>
    <h4>Description</h4>
    <p className='text-wrap pe-5' style={{height:120}}>
    {String(a?.description).length > 400?String(a?.description).substring(0,400)+"...":a?.description}
    </p>
  </div>
  <div >
  {a?.amenities?.filter((a,i)=>i < 4).map((a,i)=>{
  return <span className='amenitiesIconsLarge' key={i} >
  <img
  src={a.icon}
  style={{width:30}}
  />
  <span className='px-2'><small>{a.name}</small></span>
  </span>})}
  </div>
  <div >
  <NavLink to={`/${String(a?.type).toLocaleLowerCase()}/${a?.id}`}>
    <small className='p-3'>Vew More...</small>
    </NavLink>
  </div>
</div> 
</div>
    </div>)}
{list.length > 1 && <>
  <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="prev">
    <span className="carousel-control-prev-icon" aria-hidden="true"></span>
    <span className="visually-hidden">Previous</span>
  </button>
  <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="next">
    <span className="carousel-control-next-icon" aria-hidden="true"></span>
    <span className="visually-hidden">Next</span>
  </button>
  </>}
</div>
{selectedImages &&<ImagePreview 
  onClose={()=>{
  setSelectedImages(undefined)
  }}
  imageList={selectedImages}
  />}
</section>)

}