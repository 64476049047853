import React,{useState} from "react";
import { APIResponse, PostData } from "./function";
import { HandlePostImageUploadProp } from "./types";
const useHttpHooks = ()=>{
const [loading,setLoading] = useState<boolean>(false)
const handlePostImageUpload =(prop:HandlePostImageUploadProp)=>{
    setLoading(true);
    return new Promise<APIResponse>((resolve)=>{
        PostData("uploadPostImage",prop,true).then((res)=>{
            setLoading(false);
            resolve(res)
        })
    })
}
return {
    loading,
    handlePostImageUpload
}
}
export default useHttpHooks;
