import { ReducerObjs } from "../includes/constants";
import { ReducerAction, ReducersProps } from "../includes/types";
import UpdateUserReducer, { userInitialState } from "./userSlice";
const initialValues:ReducersProps = {
    userData:userInitialState
}
const AppReducer = (state = initialValues, action:ReducerAction) => {
    switch (action.type) {
      case ReducerObjs.UpdateUser:
        return UpdateUserReducer(state,action);
        default:
        return state;
    }
  }
  
  export default AppReducer;