/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from 'react'
import { ItemProps, listOfLatlng } from '../../includes/types'
import { ListView, Thumbnail } from '../home/sections/sectionTwo'
import { SearhBarComponent } from '../../components/searchbar'
import MapHandlerComponent from '../../includes/mapHandler'
import { GetData, PostData } from '../../includes/function'
import Pagination from '../../components/pagination'
import ToggleList from '../../components/toggleList'
import { BaseLoader } from '../../css/spinnner'
import { BaseBanner } from '../../components/baseBanner'

const HomesListingScreen = () => {
  const [filterString,setFilterString] = useState<string>("")
  const [loading,setLoading] = useState<boolean>(false)
  const [selectedView,setSelectedView] = useState<"thumbnail"|"list">("thumbnail")
  const [numberOfitems,setNumberOfitems] = useState<number  | undefined>(0)
  const [totalItem,setTotalItem] = useState<number | undefined>(0)
  const [hasNextPage,setHasNextPage] = useState<boolean  | undefined>(false);
  const [ListOfLatlng,setListOfLatlng] = useState<listOfLatlng[]>([])

  const [page,setPage] = useState<number>(1)
  const [list,setList] = useState<ItemProps[]>(Array.from({length:9}).map((a,i)=>{
      return { }
  }))
const GetLatestHouses = (page:number)=>{
  setPage(page)
  GetData("recent_houses",{
    page,
    address:"Nigeria"
  },false).then((res)=>{
if(res.status)
{
  // const latlng:Item = res.result
  setList(res.result)
  setTotalItem(res.totalItem);
  setHasNextPage(res.hasNextPage);
  setNumberOfitems(res.numberOfitems);
  setListOfLatlng(res.result.map((a:ItemProps,i:number)=>{
    return {
      latitude:a.latitude,
      longitude:a.longitude
    }
  }).filter((a:listOfLatlng,i:number)=>a.lat && a.lng))
  }
  })
}
const SearchHouses = (d:any)=>{
setLoading(true);
PostData("search_houses",{...d,page},true).then((res)=>{
  setLoading(false);
  if(res.status)
  {
    if(res.result.length !== 0)
    {
      document.body.scrollTop = document.documentElement.scrollTop = 350;
    }
  }
})
}
useEffect(()=>{
  GetLatestHouses(page)
},[])
var AllItems = list;
if(filterString !== "")
{
  AllItems = AllItems.filter((a,i)=>a.purpose === filterString)
}

  const placeholder = Array.from({length:12}).map((a,i)=>{
    return {

    }
  })
  AllItems = [...AllItems,...placeholder.filter((a,i)=>i > AllItems.length - 1)]
  return (<main className='content' style={{minHeight:600}} >
       <div className='p-3' >
      <div className='pageTitle ms-3'>Houses</div>
      <div className='row' >
      <div className='col-7' >
      <SearhBarComponent 
      onValue={(d:any)=>{
       SearchHouses(d);
      }}
      type="house"
      />
      </div>
      <div className='col-5' >
      <MapHandlerComponent
          coordinates={ListOfLatlng}
      />
        </div>
</div>
<div 
className='ps-3' 
>
<ToggleList 
title='List of houses'
subList={[]}
selected={selectedView}
onChange={(d)=>setSelectedView(d)}
onFilter={(d:string)=>{
  setFilterString(d)
}}
/>
</div>
{loading?<div className='p-3'>
  <BaseLoader size={"small"}/>
  <span className="ps-2">Fetching...</span>
</div>:AllItems.filter((a,i)=>a.title).length === 0?<BaseBanner
message="No item found!"
/>:null}
 <div 
className='row ps-3' 
>
{AllItems.map((item,i)=>selectedView === "thumbnail"?<Thumbnail
{...item}
numberOfItems={3}
onSearchSub={(sub:string)=>{
  SearchHouses({})
}}
/>:<ListView
{...item}
numberOfItems={6}
onSearchSub={(sub:string)=>{
  SearchHouses({})
}}
/>)}
</div>
<div className='mb-3 ps-3' >
<Pagination  
onNext={()=>{
  GetLatestHouses(page + 1)
}}
onPrev={()=>{
  GetLatestHouses(page - 1)
}}
numberOfitems={numberOfitems}
hasNextPage={hasNextPage}
totalItem={totalItem}
/>
</div>
 </div> 
    </main>
  )
}

export default HomesListingScreen;