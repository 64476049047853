import React, {} from 'react';
import './style.css';
import  HomeScreenBgImage  from '../../../images/home-page-background.png';
import SearchSection from './searchSection';
export function SectionOne() {
 
return (<section className="d-flex flex-column  justify-content-center ">
<div 
style={{backgroundImage: `linear-gradient(to bottom, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.6)),url("${HomeScreenBgImage}")`,    backgroundRepeat: "no-repeat",
backgroundSize:"cover"}} 
className="bg-cover d-flex justify-content-center ">
<SearchSection 

/>
</div>
</section>)
}