import React, { useEffect} from "react";
import {Map,APIProvider,AdvancedMarker} from '@vis.gl/react-google-maps'
import { listOfLatlng } from "./types";
import { MapPinIcon } from "../assets/icons";
interface MapProps {
  coordinates:listOfLatlng[]
}
type Points = google.maps.LatLngAltitudeLiteral & {key:string;}
type props = {points:Points[]}

const MapHandlerComponent = (props:MapProps)=>{

useEffect(()=>{
 
},[])
return <div id="map-container">
      <APIProvider
        apiKey={"AIzaSyDHlvqtpYvHYYqE-EnifXQGn8KXu8Bc31Y"}
      >
 <Map 
 center={{lat:9.071520,lng:7.444130}}
 style={{width:"100%",height:"100%"}}
 zoom={10}
 mapId={"sksksk"}

 >
  <Markers points={[
    {lat:9.071520,lng:7.444130,key:"s",altitude:3}
  ]} />
 </Map>
      </APIProvider>
</div>
}
export default MapHandlerComponent;

const Markers = ({points}:props)=>{
return <>
{points.map((point,i)=><AdvancedMarker 
key={point.key} 
position={point}
>
<MapPinIcon 
size={40}
color="red"
/></AdvancedMarker>)}
</>
}