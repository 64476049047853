import React, {useState } from "react";
import { Formik, FormikValues } from "formik";
import * as y from "yup";
import BaseModal from "../baseModal";
import { InputField, SubmitButton } from "../forms/inputField";
import { CaretBackIcon } from "../../assets/icons";
import { PostData, SendToast } from "../../includes/function";
import { CountrylistModel, UserDataProps } from "../../includes/types";
const schema = y.object({
  mobile:y.string().required("Mobil number is required.")
})
interface MobileNumberUpdateModalProps {
onClose:(data?:string)=>void;
onSucess:(data:any)=>void; 
title?:string;
noToken?:boolean;
}
const MobileNumberUpdateModal = (props:MobileNumberUpdateModalProps)=>{
const [switchOtp,setSwitchOtp] = useState<boolean>(false);
const [loading,setLoading] = useState<boolean>(false);
const [message,setMessage] = useState<string>("");
const [selectedCountry,setSelectedCountry] = useState<CountrylistModel>({});

const VerifyMobileNumber = (values:FormikValues)=>{
setLoading(true);
PostData(props.noToken?"verify_otp":"update_mobile_number",values,true).then((res)=>{
  setLoading(false);
  if(res.status)
  {
    props.onSucess({
      ...values,
      flag:selectedCountry.flag,
      country:selectedCountry.name
    })
  }
})
}
const SendCode = (values:FormikValues)=>{
  if(props.noToken)
  {
    setLoading(true);
    PostData("send_otp_to_mobile",values,true).then((res)=>{
      setLoading(false);
      setSwitchOtp(res.status);
      if(res.status)
      {
      setMessage(res.message);
      }
    })
return ;
  }else{
const userData:string | null = localStorage.getItem("userData");
  if(userData)
  {
const u:UserDataProps = JSON.parse(userData);
if(String(u.dialCode)+String(u.mobile) !== String(values.dialCode)+String(parseInt(values.mobile)))
{
setLoading(true);
PostData("send_token",values,true).then((res)=>{
  setLoading(false);
  setSwitchOtp(res.status);
  if(res.status)
  {
    setMessage(res.message);
  }
})
  }else{
   SendToast("Same number cannot be updated.",true);
  }
}
  }
}
return <BaseModal 
onClose={(d)=>{
  props.onClose(d)
}}
type="md"
center={true}
title={props.title?props.title:"Change mobile number"}
>
<Formik 
initialValues={{
  mobile:"08161235924",
  otp:"",
  dialCode:"234",
  sendToWhatsApp:"0"
}}
onSubmit={(values)=>{
  if(!switchOtp)
  {
    const data:FormikValues = values;
    delete data.otp;
    SendCode(data);
   return ; 
  }
  if(String(values.otp).length !== 4)
  {
    SendToast("OTP must be 4 digits",true);
    return ;
  }
  VerifyMobileNumber({
    verifytoken:values.otp,
    dialCode:values.dialCode,
    mobile:parseInt(values.mobile)
  })
}}
validationSchema={schema}
>
{({values,touched,errors,setFieldValue,handleChange,handleSubmit})=><div >
{switchOtp?<div >
  <div className="" ><small>{message}</small></div>
<InputField 
max={4}
label="OTP"
value={values.otp}
onValueChange={(v:any)=>{
  setFieldValue("otp",v);
}}
name="otp"
type="otp"
error={touched.otp && errors.otp}
/>
</div>:<InputField 
max={10}
label="Mobile phone"
value={values.mobile}
onValueChange={handleChange("mobile")}
name="mobile"
placeholder="08000000000"
type="mobile"
required
SendToWhatsApp={(d)=>{
  setFieldValue("sendToWhatsApp",d?1:0);
}}
onDialCode={(countryObj)=>{
    setFieldValue("dialCode",countryObj.calling_code);
    setSelectedCountry(countryObj);
}}
error={touched.mobile && errors.mobile}
/>}
<div id="recaptcha" style={{opacity:0}} ></div>
<div >
  <SubmitButton 
  loading={loading}
  title={switchOtp?"Verify token":"next"}
  submit={handleSubmit}
  />
  {switchOtp && <SubmitButton 
  title={"back"}
  icon={<CaretBackIcon color={"white"} size={20} />}
  submit={()=>{
    setFieldValue("sendToWhatsApp",0);
    setSwitchOtp(loading)
  }}
  />}
</div>
</div>}
</Formik>
</BaseModal>
}
export default MobileNumberUpdateModal;
