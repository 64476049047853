/* eslint-disable jsx-a11y/scope */
import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import { ItemProps } from "../../../includes/types";
import { GetData, PostData } from "../../../includes/function";
import moment from 'moment';
import { TrashIcon } from "../../../assets/icons";
import { SubmitButton, CheckBoxButton } from "../../../components/forms/inputField";
import AlertBox from "../../../components/DialogBox";
const MyFavourite = ()=>{
const [section,setSection] = useState<number>(0);

useEffect(()=>{

},[])
return <div className="dashboard">
<h3>My Favourite</h3>
<nav>
  <div className="nav nav-tabs" id="nav-tab" role="tablist">
    <button onClick={()=>{
        setSection(0)
    }} className={`nav-link ${section === 0?"active":""}`}  data-bs-toggle="tab" data-bs-target="#nav-homes" type="button" role="tab"  aria-selected="true">Houses</button>
    <button onClick={()=>{
        setSection(1)
    }} className={`nav-link ${section === 1?"active":""}`}   data-bs-toggle="tab" data-bs-target="#nav-properties" type="button" role="tab" aria-selected="false">Lands</button>
    </div>
</nav>
<div className="tab-content" id="nav-tabContent">
<div className="table-container">
{section === 0 && <Houses />}
{section === 1 &&<Lands />}
</div>
</div>
</div>
}
export default MyFavourite;

const Houses = ()=>{
    const [list,setList] = useState<ItemProps[]>([]);
    const [selectedItems,setSelectedItems] = useState<string[]>([]);
    const [loading,setLoading] = useState<boolean>(false);
    const [showDailog,setShowDailog] = useState<boolean>(false);
    const GetWishlist = ()=>{
        setLoading(true);
        GetData("get_wishlist",{},false).then((res)=>{
            // alert(JSON.stringify(res));
        setLoading(false);
            if(res.status)
            {
            setList(res.data.filter((a:ItemProps,i:number)=>String(a.type).toLowerCase() === "house"))   
            }
        })
    }
    const handleRemoveItem = ()=>{
        const x = list.filter((a:ItemProps)=>!selectedItems.includes(String(a?.property_id)))
        PostData("remove_wishlist_items",{list_of_ids:selectedItems.join(",")}).then((res)=>{
            if(res.status)
            {
                setList(x)
            }
        })
    }
    useEffect(()=>{
        GetWishlist()   
    },[])
    return <><div className="tab-pane fade show active" role="tabpanel" aria-labelledby="nav-homes-tab">
    <div className="card p-2">
        <table className="table-responsive table">
            <tbody>
            {list.map((a,i)=><tr key={i} className="">
                    <td scope="row">
                    <div className="row">
                    <div className="col-2">
                    <img alt="pp" className="thumbnail thumbnail-list"
                    src={a.thumbnail}
                    />
                    </div>
                    <div className="col-10">
                        <div><NavLink to={(`/${a.type}/${a.property_id}`).toLowerCase()} ><b>{a.title}</b></NavLink></div>
                        <small className="">{String(a.description).substring(0,200)}...</small>
                        <div className="fs-6 fw-bold"><small >{moment(a.date).format("Do, MMM YYYY")}</small></div>
                    </div>
                    </div>
                    </td>
                    <td className="p-2 lx pt-5" style={{width:80}}>
                       <CheckBoxButton 
                       onValue={(d)=>{
                        const x = selectedItems.filter((b,i)=>String(b) !== String(a?.property_id))
                           if(d)
                           {
                            setSelectedItems([...x,String(a?.property_id)]);
                           }else{
                            setSelectedItems(x)
                           }
                       }}
                       value={selectedItems.includes(String(a.property_id))?1:0}
                       description=""
                       label=""
                       name=""
                       />
                    </td>
             </tr>)}
            </tbody>
        </table>
        <div >
        {selectedItems.length !== 0?<SubmitButton
        loading={loading}
          submit={()=>{
            setShowDailog(true);
          }}
         title={`${selectedItems.length} item${selectedItems.length > 1?"s":""} selected`}
          >
            <span className="ps-2" >
            <TrashIcon 
            size={20}
            />
            </span>
          </SubmitButton>:null}
        </div>
    </div>
    </div>
{showDailog && <AlertBox
title="Alert"
message={`Are you sure you want to remove ${selectedItems.length > 1?`these ${selectedItems.length} items from your list`:"this item from your list"}?`}
onClose={()=>{
setShowDailog(false);
}}
onValue={(d:string)=>{
handleRemoveItem();
setShowDailog(false);
}}
/>}
 </>
}
const Lands = ()=>{
    const [list,setList] = useState<ItemProps[]>([]);
    const [selectedItems,setSelectedItems] = useState<string[]>([]);
    const [loading,setLoading] = useState<boolean>(false);
    const [showDailog,setShowDailog] = useState<boolean>(false);
    const GetWishlist = ()=>{
        setLoading(true);
        GetData("get_wishlist",{},false).then((res)=>{
            // alert(JSON.stringify(res));
        setLoading(false);
            if(res.status)
            {
            setList(res.result.filter((a:ItemProps,i:number)=>String(a.type).toLowerCase() === "land"))   
            }
        })
    }
    const handleRemoveItem = ()=>{
        const x = list.filter((a:ItemProps)=>!selectedItems.includes(String(a?.property_id)))
        PostData("remove_wishlist_items",{list_of_ids:selectedItems.join(",")}).then((res)=>{
            if(res.status)
            {
                setList(x)
            }
        })
    }
    useEffect(()=>{
        GetWishlist()   
    },[])
    return <><div className="tab-pane fade show active" role="tabpanel" aria-labelledby="nav-homes-tab">
    <div className="card p-2">
        <table className="table-responsive table">
            <tbody>
            {list.map((a,i)=><tr key={i} className="">
                    <td scope="row">
                    <div className="row">
                    <div className="col-2">
                    <img alt="_" className="thumbnail thumbnail-list"
                    src={a.thumbnail}
                    />
                    </div>
                    <div className="col-10">
                        <div><NavLink to={(`/${a.type}/${a.property_id}`).toLowerCase()} ><b>{a.title}</b></NavLink></div>
                        <small className="">{String(a.description).substring(0,200)}...</small>
                        <div className="fs-6 fw-bold"><small >{moment(a.date).format("Do, MMM YYYY")}</small></div>
                    </div>
                    </div>
                    </td>
                    <td className="p-2 lx pt-5" style={{width:80}}>
                       <CheckBoxButton 
                       onValue={(d)=>{
                        const x = selectedItems.filter((b,i)=>String(b) !== String(a?.property_id))
                           if(d)
                           {
                            setSelectedItems([...x,String(a?.property_id)]);
                           }else{
                            setSelectedItems(x)
                           }
                       }}
                       value={selectedItems.includes(String(a.property_id))?1:0}
                       description=""
                       label=""
                       name=""
                       />
                    </td>
             </tr>)}
            </tbody>
        </table>
        <div >
        {selectedItems.length !== 0?<SubmitButton
        loading={loading}
          submit={()=>{
            setShowDailog(true);
          }}
         title={`${selectedItems.length} item${selectedItems.length > 1?"s":""} selected`}
          >
            <span className="ps-2" >
            <TrashIcon 
            size={20}
            />
            </span>
          </SubmitButton>:null}
        </div>
    </div>
    </div>
{showDailog && <AlertBox
title="Alert"
message={`Are you sure you want to remove ${selectedItems.length > 1?`these ${selectedItems.length} items from your list`:"this item from your list"}?`}
onClose={()=>{
setShowDailog(false);
}}
onValue={(d:string)=>{
handleRemoveItem();
setShowDailog(false);
}}
/>}
 </>
}