/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable jsx-a11y/alt-text */
import React, { RefObject, useEffect, useRef, useState } from "react";
import './style.css';
import { PostData } from "../../includes/function";
import { blueColor } from "../../includes/constants";
interface AdvertViewProps {
type?:string;
size?: "small" | "medium"
}
interface AdvertItemProps {
    id:number;
    description:string;
    start:string;
    end:string;
    images:string[];
    link:string;
}
export const AdvertView = (props:AdvertViewProps)=>{
    const thisContainer = useRef() as RefObject<HTMLDivElement>;
    const thisScroller = useRef() as RefObject<HTMLDivElement>;
    
    const [list,setList] = useState<AdvertItemProps[]>([])
    const [containerWidth,setContainerWidth] = useState<string>("100%");
    const [containerHeight,setContainerHeight] = useState<string>(props.size === "small"?"100px":"160px");
    const [adsIndex,setAdsIndex] = useState<number>(0);
    const GetAdsverts = ()=>{
        PostData("get_adverts",{
           type:props.type
        },false).then((res)=>{
        console.log(res);
            if(res.status)
            {
                if(thisContainer.current)
                {
                  const el = window.getComputedStyle(thisContainer.current);
                  setContainerWidth(el.width);
                  setContainerHeight(el.height);
                }
                setList(res.result);
               
            }else{
                setContainerWidth("0px");
                setContainerHeight("0px"); 
            }
        })
    }
   
    useEffect(()=>{
      GetAdsverts();  
    },[])
    useEffect(()=>{
        const x = setInterval(()=>{
            setAdsIndex(adsIndex >= list.length - 1?0:adsIndex + 1); 
          },5000)
    return ()=>{
     clearInterval(x);
    }
    },[list,adsIndex])
    
    return <div
        ref={thisContainer}
        className={`card AdsCard mb-3 ${containerWidth === "0px"?"hideElement":""}`} style={{width:containerWidth,height:containerHeight}}>
        <div 
        ref={thisScroller}
        className="ads-container"
        style={{height:containerHeight,width:"auto"}}
        >
        {list.length !== 0?<div className="ads-item"
        style={{width:containerWidth,height:containerHeight}}
        >
        <img
        onClick={()=>{
            window.location.href = list[adsIndex].link;
        }}
        src={list[adsIndex].images[0]}
        />
        </div>:null}
        </div>
        <li className="ads-dots-container">
        {list.map((a,i)=><div key={i} 
        onClick={()=>{
           setAdsIndex(i);
        }}
        style={{backgroundColor:i === adsIndex?blueColor:"#444"}}
        ></div>)}
        </li>
        <span className="ads-title">ADS</span>
    </div>
}
