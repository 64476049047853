import React, { } from "react"
import { SearhBarComponent } from "../../../components/searchbar";

const SearchSection = ()=>{

return <div 
className="search-section-main position-relative"
style={{width:"65%",paddingTop:120}}
>
<h2 className="blueTitle">Homes. Land. Rent. Sales. Short-let.</h2>
<SearhBarComponent 
get={true}
/>
</div>

}

export default SearchSection;