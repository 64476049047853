/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable jsx-a11y/alt-text */
// eslint-disable-next-line react/jsx-no-useless-fragment

import React, { useEffect, useState } from "react";
import { NotificationIcon, PowerIcon } from "../icons";
import { NavLink, useNavigate } from "react-router-dom";
import './style.css';
import { NotificationProps, UserDataProps } from "../../includes/types";
import { PlaceHolderAvatar, ROUTES } from "../../includes/constants";
import { PostData } from "../../includes/function";
import { useDispatch } from "react-redux";
import { GetUserInfo, UpdateUserInfo } from "../../redux/userSlice";
const UserHeader = ({newPost}:{newPost:()=>void})=>{
const dispatch = useDispatch()
const [userData,setUserData] = useState<UserDataProps>(GetUserInfo())
    const logoutUser = ()=>{
       localStorage.clear();
       dispatch(UpdateUserInfo({
        access_token:""
       }))
       window.location.href = "/";
    }
    return <div className="d-flex align-item-center dashboard-button-container position-relative" style={{maxWidth:280}} >
             <span  style={{display:"flex",justifyContent:"center",alignItems:"center"}}>
            <span
            onClick={newPost}
            className="blueBtn" style={{marginLeft:-1}}>
              Post a property 
              </span>
              <NavLink to={ROUTES.UserDashboard} >
             <img src={userData?.avatar?userData?.avatar:PlaceHolderAvatar} 
             className="avatar"
             style={{marginRight:10,width:25,height:25}}
             />
            </NavLink>
            </span>
        <NotificationViewButton />  
       <span 
       className="btn-default"
       onClick={()=>logoutUser()}
       style={{width:30,display:"flex",justifyContent:"center",alignItems:"center"}}
       >
          <PowerIcon
            size={20}
           />
           <br/>
       </span>
       
    </div>
}
export default UserHeader;

export const NotificationViewButton = ()=>{
    const [showNotification,setShowNotification] = useState<boolean>(false);
    const [list,setList] = useState<NotificationProps[]>([])
    const getNotifications = ()=>{
        PostData("get_notifications",{},false).then((res)=>{
            if(res.status) 
           {
            setList(res.result);
           }
        })
    }
    const UpdateNotifications = (id:string)=>{
        PostData("update_notification",{
            id:id
        },false).then((res)=>{
            if(res.status)
            {
            setList(res.result);
            }
        })
        
    }
    useEffect(()=>{
        getNotifications()
    },[])
    const navigate = useNavigate();
    if(list.length === 0)
    {
        return null;
    }
    return <div className="position-relative notificationContainer" >
          <span
            className="btn-default position-relative"
            onClick={()=>setShowNotification(!showNotification)}
            style={{width:20,display:"flex",justifyContent:"center",alignItems:"center"}}>
                <NotificationIcon 
                size={20}
                />
                <small className="notificationCounter" >{list.length}</small>
            </span>
            {showNotification && <div className="position-absolute" style={{right:0,top:32}}>
       <div className="position-relative">
       <ul className="dropdown-container" style={{maxWidth:200}} >
        {list.map((item,i)=><li 
        key={i} >
        <div className="dropdown-item fs-7"
        style={{color:"black"}}
        onClick={()=>{
        if(item?.link)
        {
            UpdateNotifications(String(item?.id));
            navigate(item?.link)
            setShowNotification(false);
        }
        }}
        >
            {item?.icon && <div className="icon"></div>}
            {item.title}
            </div>
        </li>)}
      </ul>
      </div>
      </div>}
    </div>
}