/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { StrictMode, useEffect, useState } from 'react';
import {NavLink} from 'react-router-dom';
import './style.css';
import { DevelopersProps, ItemProps, PaginationProps } from '../../../includes/types';
import { Thumbnail } from './sectionTwo';
import { PostData } from '../../../includes/function';
import Pagination from '../../../components/pagination';
import { ROUTES } from '../../../includes/constants';
import RatingView from '../../../components/Rating';
import { AdvertView } from '../../../components/adverts';
import { MapPinIcon } from '../../../assets/icons';
export function DevelopersSection() {
  const [loading,setLoading] = useState<boolean>(false)
  const [page,setPage] = useState<number>(1);
  const [pageProps,setPageProps] = useState<PaginationProps>({})
  const [list,setList] = useState<DevelopersProps[]>([]);
  const [placeholder,setPlaceHolder] = useState<DevelopersProps[]>(Array.from({length:8}).map((a,i)=>{
    return { }
  }))
const GetDevelopers = (page:number)=>{
  PostData("developers_list",{
    page,
    address:"Nigeria"
  },false).then((res)=>{
if(res.status)
{
  setList(res.data);
  setPageProps({
    totalItem:res.totalItem,
    hasNextPage:res.hasNextPage,
    numberOfitems:res.numberOfitems
  });
}
  })
}

useEffect(()=>{
  GetDevelopers(1)
},[])

return (<section className="p-5 py-2 ">
<h3>Developer</h3>
<p>Property developer ({list.length})</p>
<div 
className="row "
>
{[...list.filter((item,i)=>i < 8),...placeholder.filter((a,i)=>i > list.filter((item,i)=>i < 8).length - 1)].map((a,i)=>a.adverts?<div
className="col-3">
  <AdvertView
key={i}
type="house"
/>
</div>:<div
className="col-3 m-0 p-2">
<div className="card mb-2 p-2" style={{margin:0}}>
<div className="d-flex"  >
<div className="thumbnail thumbnail-list">
{a?.city?<img  className=""
  src={a.logo}
  alt='x'
  style={{height:100,width:100,objectFit:"contain"}}
   />:null}
</div>
<div className="">
<div className="px-3">
  <div className={`${!a?.city?'shimmerAnimation my-2':''}`} style={{marginBottom:a?.name?10:"auto",height:a?.name?"auto":10,borderRadius:5}}>
    <NavLink to={(`/developer/${a.id}`).toLowerCase()}
     ><b >{a.name?a.name:""}</b></NavLink>
  </div>
   <small className="">{a.description?String(a.description).substring(0,190)+"...":""}</small>
  <div className={`address d-flex align-items-center`} >
  <MapPinIcon 
  size={16}
  />
  <div className={`${!a?.city?'h-thumbnail shimmerAnimation':''}`} style={{minWidth:100,height:a?.name?"auto":10,borderRadius:5}}>{a?.city?`${a?.city} ${a?.state} , Nigeria`:""}</div>
  </div>
    {a?.name ?<div  >
    <small className='address'>No of Projects: <span className="blueText">{a.noOfProjects}</span></small>
   </div>:null}
  <div className='d-flex justify-content-end align-items-end'>
    <RatingView
    value={a?.rating?a?.rating:1}
    />
 </div>
   </div>
   </div>
 </div>
</div>
</div>)}
</div>
{!loading && <Pagination  showMore={()=>{
  window.location.href = `${ROUTES.Developers}?type=developer`;
 return ;
}} />}
</section>)
}