/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-empty */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useRef, useState } from "react";
import { CheckBoxButton, InputField, SelectField, SubmitButton, TextAreaField } from "../forms/inputField";
import { Formik} from "formik";
import * as y from "yup";
import { GetData, PostData, ReturnComma } from "../../includes/function";
import Country from "../../includes/utils/states.json";
import { ReactPhotoEditor } from 'react-photo-editor'
import { AddressProps, AppSettingsProps, CountryProps, ItemsProps, ResourcesProps, SubItemModel, SubscriptionProps } from "../../includes/types";
import { BaseLoader } from "../../css/spinnner";
import { AddImage, TrashIcon } from "../../assets/icons";
import { AddressIcon, EditIcon } from "../icons";
import SubscriptionPlans from "../subscription_plans";
import AmenitiesComponent from "./Amenities";
import Moment from "moment";
import axios,{AxiosError,AxiosResponse} from "axios";
import { ImageSlider } from "./slider";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "../../includes/constants";

const schema = y.object({
        title:y.string().required("Title is required."),
        description:y.string().required("Description is required."),
        price:y.string().required("Price is required."),
        type:y.string().required("Type is required."),
        address:y.string().required("Address is required."),
        purpose:y.string().required("Purpose is required."),
        sub_type:y.string().required("Sub type is required."),
        land_mark:y.string().required("Land mark is required."),
        state:y.string().required("State is required."),
        city:y.string().required("City is required."),
})
interface NewHomeListingProps {
    onClose:()=>void;
}
// import 'react-photo-editor/dist/style.css'
export const NewHomeListing = (props:NewHomeListingProps)=>{
  const navigate = useNavigate()
  const [settings,setSetting] = useState<AppSettingsProps>()
  const [AddressList,setAddressList] = useState<AddressProps[]>([
      ]) 
  const [subscriptionPlans,setSubscriptionPlans] = useState<SubscriptionProps[]>([]);
  const [activeSubscription,setActiveSubscription] = useState<SubscriptionProps | null>(null);
  const [showImages,setShowImages] = useState<boolean>(false);
  const [loadingSec,setLoadingSec] = useState<string>("");
  const [loading,setLoading] = useState<boolean>(false);
    const [checkPlan,setCheckPlan] = useState<boolean>(false);
    const [showPayment,setShowPayment] = useState<boolean>(false);
    const [showSuccess,setShowSuccess] = useState<boolean>(false);
    const [showEditor,setShowEditor] = useState<boolean>(false);
    const [file, setFile] = useState<File | undefined>()
    const [images, setImages] = useState<ResourcesProps[]>([]);
    const [imageURLS, setImageURLs] = useState(["","","","","","","","",""]);
    const [showAddress, setShowAddress] = useState<boolean>(false);
    const [Progress,setProgress] = useState<number>(0);
    const [sessionId,setSessionId] = useState<string>("");

const GetUserPlan = ()=>{
  setCheckPlan(true);
  PostData("get_subscription",{},false).then((res)=>{
  setCheckPlan(false);
  if(res.status)
  {
  
  }
  })
}
const UploadImage = (file:FileList,index:number)=>{
setProgress(0);
setUploadCompleted(false)
const formData = new FormData();
const token:string | null = localStorage.getItem("token");
formData.append("singleFile", file[0]);
formData.append("sessionId", sessionId);
formData.append("imageIndex", String(index));
axios.post(String(process.env.APIBaseUrl+"single_file_upload"),formData,{
headers:{
  "content-type":"multipart/form-data",
  "token":token,
  "subfolder":"temp"
},
  onUploadProgress:(e)=> {
  if(e.total)
  {
  const percentComplete = Math.round(100 * e.loaded) / e.total;
  setProgress(percentComplete);
  }
},
}).then(({data}:AxiosResponse)=>{
  images[index] = data.result;
  setImages(images);
  console.log(images);
  setTimeout(()=>{
    setUploadCompleted(true)
    setProgress(0);
  },1500)
}).catch((error:AxiosError)=>{
  console.log(error);
  setTimeout(()=>{
    setUploadCompleted(true)
    setProgress(0);
  },1500)
})

}
const [fetchingAddress,setFetchingAddress] = useState<boolean>(false);
const thisNewListing = useRef<HTMLDivElement>(null)
const [addressType,setAddressType] = useState<"address"|"bus stop"|"land mark">("address")
const [listOfTypes,setListOfType] = useState<ItemsProps[]>([])
const [listOfSubTypes,setListOfSubType] = useState<SubItemModel[]>([])
const [allState,setAllStates] = useState<CountryProps[]>([]);
const [allCities,setAllCities] = useState<any[]>([]);
const thisForm = useRef<any>();
const [uploadCompleted,setUploadCompleted] = useState(true)
const [selectedIndex,setSelectedIndex] = useState<number | null>(null);
const GetSettings = ()=>{
        setLoading(true);
        GetData("app_settings",{},false).then((res)=>{
          setLoading(false);
        if(res.status)
        {
          setListOfType(res.data.hostTypes);
          setSubscriptionPlans(res.data.subscriptionPlans);
          const found = res.data.subscriptionPlans.find((a:SubscriptionProps,i:number)=>a.status === "active" || a.status === "expired")
          if(found)
          {
            setActiveSubscription(found);
          }else{
            setActiveSubscription(res.data.subscriptionPlans[0]);
          }
        }else{
          navigate(ROUTES.Login);
        }
        })
} 
useEffect(() => {
 GetSettings();
 GetUserPlan();
 setSessionId(Moment().format("hhmmss"));
 const cn:CountryProps[] = Country as CountryProps[];
 setAllStates(cn);
 return ()=>{
  if(images.length !== 0)
  {
    PostData("delete_all_temp_files",{},false);
  }
 }
},[]);
const DeleteFile = (index:number)=>{
  if(images[index].uri)
  {
  const fileInfo = String(images[index].uri);
  PostData("delete_file",{
    fileName:fileInfo
  },false);
  }
}
const thisFileInput = useRef<HTMLInputElement>(null);
  return <Formik 
    initialValues={{
      title:"",
      description:"",
      type:"",
      thumbnail:"",
      images:[],
      country:"Nigeria",
      state:"",
      city:"",
      price:"",
      address:"",
      land_mark_latitude:"",
      land_mark_longitude:"",
      address_latitude:"",
      address_longitude:"",
      addressPlaceHolder:"",
      purpose:"",
      sub_type:"",
      numberOfBeds:"1",
      size:"",
      numberOfBathrooms:"1",
      numberOfToilets:"1",
      furnished:false,
      serviced:false,
      newly_build:false,
      land_mark:"",
      payment_duration:"Daily",
      installment:false,
      amenities:[],
      promote:false
    }}
    onSubmit={(values)=>{
    if(showImages)
    {
      if(images.length !== 0)
      {
      setLoading(true);
      const v:any = values;
      delete v?.addressPlaceHolder;
      PostData("property_listing",{
        ...v,
        images:JSON.stringify(images),
        amenities:JSON.stringify(values.amenities),
        thumbnail:images[0].uri
      }).then((res)=>{
        setLoading(false);
        if(res.status)
        {
          props.onClose()
        }
      })
      }else{
        alert("Oops! aleast one image is reqiured.")
      }
    }else{
      setShowImages(true)
    }
    }}
    validationSchema={schema}
    innerRef={thisForm}
    >
{({values,touched,isValid,errors,setFieldTouched,setFieldError,handleChange,setFieldValue,handleSubmit})=>{
const imagesList:ResourcesProps[] = values.images as ResourcesProps[];
return <div className="" ref={thisNewListing}  tabIndex={-1} >
<div className="my-3">
<div className="alert alert-success p-3" >
  {checkPlan?<div className="row" >
  <div className="col-1" >
<BaseLoader />
</div>
<div className="col-10" >
  Wait while we confirm your subscription
</div>
</div>:<div 
className="row"
>
<div className="col-8 card p-0 overflow-radius-10" >
<ImageSlider 
list={imagesList}
updateImages={(list)=>{
return ;
}}
/>
  </div>
  <div className="col-4" >
  <div className="alert alert-warning pb-1" >
  <div className="row"  >
    <div >
      <b>{activeSubscription?.name}</b> {activeSubscription?.status === "active" &&<small className="fs-10">( Active Plan)</small>}
    </div>
    <div className="my-2">
      <small>{String(activeSubscription?.description).substring(0,40)}...</small>
    </div>
    <p>
      <h5 ><b>{activeSubscription?.currency}{activeSubscription?.amount}</b>/<small>month</small></h5>
    <SubmitButton 
    submit={()=>{
     if(activeSubscription?.status === "active")
     {

     }
    }}
    title={activeSubscription?.status === "active"?"Upgrade Plan":activeSubscription?.status === "expired"?"Choose a plan":"Choose a plan"}
    />
    </p>
    </div>

  </div>
  </div>
  </div>}
</div>
<div>
<InputField 
max={500}
label="Title"
value={values.title}
onValueChange={handleChange("title")}
name="title"
placeholder="e.g (my home)"
type="text"
required
error={touched.title && errors.title}
/>
<TextAreaField 
max={2000}
richtext={false}
label="Description"
value={values.description}
onValueChange={(d:any)=>{
  if(typeof d === "string")
  {
  if(d === "<p><br></p>")
  {
    d = "";
  }
  setFieldValue("description",d)
}else{
  setFieldValue("description",d.target.value)
}
}}
name="description"
placeholder="e.g my beautify home"
required
error={touched.description && errors.description}
/>
<div className="row" >
<div className="col-4" >
<InputField 
max={500}
label="Price"
value={ReturnComma(values.price)}
onValueChange={handleChange("price")}
name="price"
placeholder="1,000"
type="text"
leftIcon={"₦"}
error={touched.price && errors.price}
/>
</div>
<div className="col-4" >
  <SelectField 
  label="Installmental payment Accepted"
  placeholder="Select Installmental payment"
  value={values.installment?"Yes":"No"}
  onValueChange={(d)=>{
    setFieldValue("installment",d.value === "1")
  }}
  error={touched.installment && errors.installment}
  options={[
    {name:"No",value:"0"},
    {name:"Yes",value:"1"}
]}
  />  
</div>
<div className="col-4" >
  <SelectField 
  label="Payment duration"
  value={values.payment_duration}
  onValueChange={(d)=>{
    // alert(JSON.stringify(d))
    setFieldValue("payment_duration",d.value)
  }}
  options={["Daily","Weekly","Monthly","Yearly"].map((a,i)=>{
    return {
      name:a,value:a
    }
  })}
  error={touched.payment_duration && errors.payment_duration}
  />  
</div>
</div>
<div className="row" >
<div className="col-4" >
<SelectField 
  label="Purpose"
  value={values.purpose}
  onValueChange={(d)=>{
    // alert(JSON.stringify(d))
    setFieldValue("purpose",d.name)
  }}
  options={[
    {name:"For Rent",value:"Rent"},
    {name:"For Sale",value:"Sale"},
    {name:"For Short let",value:"Short_let"}
  ]}
  /> 
</div>
<div className="col-4" >
  <SelectField 
  label="Type"
  placeholder="Select type"
  value={values.type}
  onValueChange={(d)=>{
    setFieldValue("type",d.value)
    if(d.sub)
    {
    setFieldValue("sub_type","")
    setListOfSubType(d.sub)
    }
  }}
  error={touched.type && errors.type}
  options={listOfTypes.filter((a,i)=>a.name !== "Land").map((a,i)=>{
    return  a;
  })}
  />  
</div>
<div className="col-4" >
  <SelectField 
  label="Sub type"
  placeholder="Select sub type"
  value={values.sub_type}
  onValueChange={(d)=>{
    // alert(JSON.stringify(d))
    setFieldValue("sub_type",d.value)
  }}
  error={touched.sub_type && errors.sub_type}
  options={listOfSubTypes.map((a,i)=>{
    return  a;
  })}
  />  
</div>
</div>
<div className="row" >
<div className="col-4" >
  <SelectField 
  label="Number of bathrooms"
  value={values.numberOfBathrooms}
  onValueChange={(d)=>{
    // alert(JSON.stringify(d))
    setFieldValue("numberOfBathrooms",d.value)
  }}
  options={Array.from({length:10}).map((a,i)=>{
    return {
      name:String(i+1),value:String(i+1)
    }
  })}
  />  
</div>
<div className="col-4" >
  <SelectField 
  label="Number of beds"
  value={values.numberOfBeds}
  onValueChange={(d)=>{
    // alert(JSON.stringify(d))
    setFieldValue("numberOfBeds",d.value)
  }}
  options={Array.from({length:10}).map((a,i)=>{
    return {
      name:String(i+1),value:String(i+1)
    }
  })}
  />  
</div>
<div className="col-4" >
  <SelectField 
  label="Number of toilets"
  value={values.numberOfToilets}
  onValueChange={(d)=>{
    // alert(JSON.stringify(d))
    setFieldValue("numberOfToilets",d.value)
  }}
  options={Array.from({length:10}).map((a,i)=>{
    return {
      name:String(i+1),value:String(i+1)
    }
  })}
  />  
</div>
</div>
<div className="alert alert-success" >
<div className="row" >
<div className="col-6" >
  <SelectField 
  label="Location (State)"
  placeholder="Select state"
  value={values.state}
  onValueChange={(d)=>{
    setFieldValue("state",d.value);
    if(d.cities)
    {
    setAllCities(d?.cities)
    setFieldValue("city","")
    }
  }}
  options={allState.map((a,i)=>{
    return {
      name:a.name,value:a.name,cities:a.cities
    }
  })}
  error={touched.state && errors.state}
  />  
</div>
<div className="col-6" >
  <SelectField 
  label="Location (City)"
  value={values.city}
  placeholder="Select a city"
  onValueChange={(d)=>{
    // alert(JSON.stringify(d))
    setFieldValue("city",d.value)
  }}
  options={allCities.map((a,i)=>{
    return {
      name:a,value:a
    }
  })}
  error={touched.city && errors.city}
  />  
</div>
</div><label className="form-label" >Address</label>
<div 
onClick={()=>{
  if(values.state === "" || values.city === "" )
  {
    thisNewListing.current?.scroll({top:400,behavior:"smooth"});
    thisForm.current.handleSubmit()
    // alert("State is required");
    return ;
  } 
  
  setShowAddress(true)
  setAddressType("address")
  setFieldValue("land_mark","");
  thisNewListing.current?.scroll({top:0,behavior:"smooth"});
}}
className="roundInput row left-icon-wrap"
style={{marginTop:5,cursor:"pointer"}}
>
<div className="left-icon col-2">
  <AddressIcon 
  size={20}
  />
</div>
<div className="col-10" >
 {values.address === ""?"e.g (12 golden streeet)":values.address}
 {loadingSec === "address"?<span 
  style={{position:"absolute",right:30}}
  ><BaseLoader
  size={"small"}
  /></span>:null}
  </div>
</div>
{touched.address && errors.address?<div className="error">{errors.address}</div>:null}
<label className="form-label mt-3" >Nearby Land mark</label>
<div 
onClick={()=>{
  if(values.state === "" || values.city === "" || values.address === "")
  {
    thisNewListing.current?.scroll({top:400,behavior:"smooth"});
    thisForm.current.handleSubmit()
    // alert("State is required");
    return ;
  } 
  
  setAddressType("land mark")
  setShowAddress(true)
}}
className="roundInput row left-icon-wrap"
style={{marginTop:5,cursor:"pointer"}}
>
<div className="left-icon col-2">
  <AddressIcon 
  size={20}
  />
    </div>
  <div className="col-10" >
 {values.land_mark === ""?"e.g (Shopping Mall)":values.land_mark}
 {loadingSec === "land mark"?<span 
  style={{position:"absolute",right:30}}
  ><BaseLoader
  size={"small"}
  /></span>:null}
  </div>
</div>
{touched.land_mark && errors.land_mark?<div className="error">{errors.land_mark}</div>:null}
</div>
<div className="alert alert-success" >
<div className="row" >
<div className="col-4" >
  <SelectField 
  label="Furnished"
  value={values.furnished?"Yes":"No"}
  onValueChange={(d)=>{
    // alert(JSON.stringify(d))
    setFieldValue("furnished",d.value === "1")
  }}
  options={[
    {name:"No",value:"0"},
    {name:"Yes",value:"1"}
  ]}
  />  
</div>
<div className="col-4" >
  <SelectField 
  label="Serviced"
  value={values.serviced?"Yes":"No"}
  onValueChange={(d)=>{
    // alert(JSON.stringify(d))
    setFieldValue("serviced",d.value === "1")
  }}
  options={[
    {name:"No",value:"0"},
    {name:"Yes",value:"1"}
  ]}
  />  
</div>
<div className="col-4" >
<SelectField 
  label="Newly build"
  value={values.newly_build?"Yes":"No"}
  onValueChange={(d)=>{
    // alert(JSON.stringify(d))
    setFieldValue("newly_build",d.value === "1")
  }}
  options={[
    {name:"No",value:"0"},
    {name:"Yes",value:"1"}
  ]}
  /> 
</div>
</div> 

</div>
<div className="card p-3" >
  <label className="pb-3">Amenities</label>
<AmenitiesComponent 
onValue={(d)=>{
  setFieldValue("amenities",d);
}}
/>
</div>
</div>
</div>
<div >
</div>
</div>
}}
</Formik>
}

