/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable @typescript-eslint/no-empty-function */
import React, { useEffect, useState } from 'react'
import '../style.css';
import { DevelopersProps, UserDataProps, WindowMessageProps } from '../../../includes/types';

import { NavLink } from 'react-router-dom';
import { AddressIcon, EmailIcon, MobileIcon } from '../../../components/icons';
import { PostData } from '../../../includes/function';
import { CheckIcon} from '../../../assets/icons';
import { PlaceHolderAvatar} from '../../../includes/constants';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { Formik } from 'formik';
import * as y from 'yup';
import Moment from 'moment';
import { SubmitButton } from '../../../components/forms/inputField';
import PaymentView from '../../../components/payment';
import RatingView from '../../../components/Rating';
import AlertBox from '../../../components/DialogBox';
const AppointmentSchema = y.object({
  selectedDate:y.string().required("Date is required."),
  selectedTime:y.string().required("Time is required."),
  message:y.string().required("Message is required."),
  agree:y.bool().oneOf([true], 'Field must be checked')
})
export const DeveloperAppointment = (props:DevelopersProps)=>{
  const [loading, setLoading] = useState<boolean>(false);
  const [reminder, setReminder] = useState<boolean>(false);
  const [message, setMessage] = useState<string>("");
  const SendMessage = (values:any)=>{
    if(loading)
    {
      return ;
    }
   setLoading(true);
   PostData("book_appointment_with_developer",values).then((res)=>{
   setLoading(false);
   if(!res.status && res.result.pending)
   {
    setTimeout(()=>{
    setReminder(true);
    setMessage(res.message);
  },1500)
   }
   })
  }
const [paymentAmount,setPaymentAmount] = useState<string>("")
const [saveData,setSaveData] = useState<any>()
  useEffect(()=>{
  },[])
  return <>
   <AgentInfo 
   {...props}
   />
   <div className='row'>
   <div className='agentTitle'>Schedule Appointment</div>
   <div className='tourContainer p-3'>
   <Formik 
   initialValues={{
     selectedDate:"",
     selectedTime:"",
     message:"",
     sms:false,
     agree:false,
     developerId:props.id,
     reminder:false
   }}
   validationSchema={AppointmentSchema}
   onSubmit={(values)=>{
     if(values.sms)
     {
     values.reminder = false;
     setSaveData(values)
     setPaymentAmount("500");
     }else{
     SendMessage(values)
     }
   }}
   >
     {({values,errors,setFieldValue,handleSubmit})=><>
   <div className='mb-0'>
   <label><small>Date:</small></label>
   <div >
   <DatePicker 
   required
   onChange={(d:any)=>{
     setFieldValue("selectedDate",d)
   }}
   selected={values.selectedDate === ""? new Date():new Date(String(values.selectedDate))}
   minDate={new Date(Moment().add("d",1).toISOString())}
   maxDate={new Date(Moment().add("d",28).toISOString())}
   />
   </div>
   {errors.selectedDate && <label className='tour-error'>{errors.selectedDate}</label>}
   </div>   
   <div className='mb-3'>
   <label>
       <small>Time:</small>
   </label>
   <select
   required
   onChange={(d)=>{
     setFieldValue("selectedTime",d.target.value)
   }}
   value={values.selectedTime}
   name="sat-time" className="form-control roundInput" tabIndex={-98} >
   <option selected value="10:00 am">10:00 am</option>
   <option value="10:15 pm">10:15 pm</option>
   <option value="10:30 pm">10:30 pm</option>
   <option value="12:00 pm">12:00 pm</option>
   <option value="12:15 pm">12:15 pm</option>
   <option value="12:30 pm">12:30 pm</option>
   <option value="12:45 pm">12:45 pm</option>
   <option value="01:00 pm">01:00 pm</option>
   <option value="01:15 pm">01:15 pm</option>
   <option value="01:30 pm">01:30 pm</option>
   <option value="01:45 pm">01:45 pm</option>
   <option value="02:00 pm">02:00 pm</option>
   <option value="05:00 pm">05:00 pm</option>
   </select>
   {errors.selectedTime && <label className='tour-error' style={{marginTop:-8}}>{errors.selectedTime}</label>}
   </div>
   <div className='mb-3'>
   <label>
       <small>Message:</small>
   </label>
   <textarea 
   required
   maxLength={120}
   rows={4} 
   className='form-control roundTextarea text-break mb-3'
   placeholder='Hello, I am interested in'
   onChange={(d)=>{
     setFieldValue("message",d.target.value)
   }}
   value={values.message}
   />
   <small style={{fontSize:"0.7rem",float:"right"}} className='text-positive'>{values.message.length}/120</small>
   {errors.message && <label className='tour-error'>{errors.message}</label>}
   </div> 
   <div >
 <div className="form-check" style={{display: "inline-flex"}}>
 <input className="form-check-input"
 onChange={(d)=>{
   setFieldValue("sms",!values.sms)
 }}
 type="checkbox"  id="flexCheckChecked" />
 <label className="form-check-label"  >
 Send via sms - <small>(This will attract the sum of N500 Per SMS)</small>
 </label>
 </div>
 <div className="form-check" style={{display: "inline-flex"}}>
 <input className="form-check-input" type="checkbox" 
 onChange={(d)=>{
   // alert(d.target.value)
   setFieldValue("agree",!values.agree)
 }}
 required
  />
 <label className="form-check-label mb-3"  >
 I consent to having this website store my submitted information so they can respond to my inquiry.
 </label>
 </div>
 {errors.agree && <label className='tour-error ms-5'>{errors.agree}</label>}

   </div>
   <div >
     <SubmitButton
     submit={handleSubmit}
     loading={loading}
     title='Submit'
      />
     </div>  
     </>}
  </Formik>
   </div>
   </div>
   {paymentAmount !== ""? <PaymentView 
    amount={paymentAmount}
    type='tour'
    onClose={()=>{
      setPaymentAmount("")
    }}
    memo=''
    onValue={(data:WindowMessageProps)=>{
      if(data.message === "Approved")
      {
        SendMessage(saveData);
        setPaymentAmount("") 
      }else if(data.message === "close")
      {
        setPaymentAmount("") 
      }
      console.log(data);
    }}
    />:null}
    {reminder && <AlertBox 
    message={message}
    onClose={()=>setReminder(false)}
    onValue={()=>{
      setSaveData({...saveData,reminder:true})
      setPaymentAmount("500");
      setReminder(false)
    }}
    title='Send an sms reminder'
    />}
   </>
}
export const BookATour = (props:DevelopersProps)=>{
   const [agentObject,setAgentObject] = useState<UserDataProps | undefined>()
   const [loading, setLoading] = useState<boolean>(false);
   const [saveData,setSaveData] =  useState<any>();
   const SendMessage = (values:any)=>{
    if(loading)
    {
      return ;
    }
    setLoading(true);
    PostData("tour_request",values).then((res)=>{
    setLoading(false);
    })
   }
const [paymentAmount,setPaymentAmount] = useState<string>("")
   useEffect(()=>{
    
   },[])
   return <>
    <AgentInfo 
    {...props}
    />
    {!agentObject?.user_verified && <div className='row'>
    <div className='agentTitle'>Schedule A Tour</div>
    <div className='tourContainer p-3'>
    <Formik 
    initialValues={{
      selectedDate:"",
      selectedTime:"",
      message:"",
      sms:false,
      agree:false,
      email:"",
      mobile:"",
    }}
    validationSchema={AppointmentSchema}
    onSubmit={(values)=>{
      if(values.sms)
      {
      setSaveData(values)
      setPaymentAmount("500");
      }else{
        SendMessage(values)
      }
    }}
    >
      {({values,errors,setFieldValue,handleSubmit})=><>
    <div className='mb-0'>
    <label><small>Date:</small></label>
    <div >
    <DatePicker 
    required
    onChange={(d:any)=>{
      setFieldValue("selectedDate",d)
    }}
    selected={values.selectedDate === ""? new Date():new Date(String(values.selectedDate))}
    minDate={new Date(Moment().add("d",1).toISOString())}
    maxDate={new Date(Moment().add("d",28).toISOString())}
    />
    </div>
    {errors.selectedDate && <label className='tour-error'>{errors.selectedDate}</label>}
    </div>   
    <div className='mb-3'>
    <label>
        <small>Time:</small>
    </label>
    <select
    required
    onChange={(d)=>{
      setFieldValue("selectedTime",d.target.value)
    }}
    value={values.selectedTime}
    name="sat-time" className="form-control roundInput" tabIndex={-98} >
    <option selected value="10:00 am">10:00 am</option>
    <option value="10:15 pm">10:15 pm</option>
    <option value="10:30 pm">10:30 pm</option>
    <option value="12:00 pm">12:00 pm</option>
    <option value="12:15 pm">12:15 pm</option>
    <option value="12:30 pm">12:30 pm</option>
    <option value="12:45 pm">12:45 pm</option>
    <option value="01:00 pm">01:00 pm</option>
    <option value="01:15 pm">01:15 pm</option>
    <option value="01:30 pm">01:30 pm</option>
    <option value="01:45 pm">01:45 pm</option>
    <option value="02:00 pm">02:00 pm</option>
    <option value="05:00 pm">05:00 pm</option>
    </select>
    {errors.selectedTime && <label className='tour-error'>{errors.selectedTime}</label>}
    </div>
    <div className='mb-3'>
    <label>
        <small>Message:</small>
    </label>
    <textarea 
    required
    maxLength={120}
    rows={4} 
    className='form-control roundTextarea text-break mb-3'
    placeholder='Hello, I am interested in'
    onChange={(d)=>{
      setFieldValue("message",d.target.value)
    }}
    value={values.message}
    />
    <small style={{fontSize:"0.7rem",float:"right"}} className='text-positive'>{values.message.length}/120</small>
    {errors.message && <label className='tour-error'>{errors.message}</label>}
    </div> 
    <div >
  <div className="form-check" style={{display: "inline-flex"}}>
  <input className="form-check-input"
  onChange={(d)=>{
    setFieldValue("sms",!values.sms)
  }}
  type="checkbox"  id="flexCheckChecked" />
  <label className="form-check-label"  >
  Send via sms - <small>(This will attract the sum of N500 Per SMS)</small>
  </label>
  </div>
  <div className="form-check" style={{display: "inline-flex"}}>
  <input className="form-check-input" type="checkbox" 
  onChange={(d)=>{
    // alert(d.target.value)
    setFieldValue("agree",!values.agree)
  }}
  required
   />
  <label className="form-check-label mb-3"  >
  I consent to having this website store my submitted information so they can respond to my inquiry.
  </label>
  {errors.agree && <label className='tour-error'>{errors.agree}</label>}
  </div>
    </div>
    <div >
      <SubmitButton
      submit={handleSubmit}
      loading={loading}
      title='Submit'
       />
      </div>  
      </>}
   </Formik>
    </div>
    </div>}
    
    </>
}

export const AgentInfo = (props:DevelopersProps)=>{
  return <div >
    <div className='row'>
    <div className='col-3'>
      <img 
      className='agentAvatar'
      src={props?.supportPersonAvatar?props?.supportPersonAvatar:PlaceHolderAvatar}
      />
    </div>
    <div className='col-7 p-4 pt-2' >
      <div className='agentTitle'>Contact {props.supportPersonName}</div>
      <div className="mb-3" style={{width:100,paddingBottom:20}}><RatingView 
        value={parseInt(String(props?.rating))}
     />
      </div>
      <div className='verified mt-2'>
          <CheckIcon />
        <span className='verifiedText'>Verified</span>
      </div>
    </div>
    </div>
    <table 
    className='table table-responsive table-bordered mb-5'
    >
     <tr  >
        <td className='t1 p-3'>RC Number:</td>
        <td className='t2 p-3 ps-0'>
          <span className='p-2'>
          <MobileIcon
          size={20}
          />
          </span>
          <span onClick={()=>{
          window.open(`https://search.cac.gov.ng/home?q=${props.RCNumber}`,'_blank');
          }} 
          style={{textDecoration:"underline",cursor:"pointer"}}
          >{props.RCNumber}</span>
        </td>
      </tr>
      <tr  >
        <td className='t1 p-3'>Mobile:</td>
        <td className='t2 p-3 ps-0'>
          <span className='p-2'>
          <MobileIcon
          size={20}
          />
          </span>
          <NavLink to={`tel:${props.phoneNumber}`} >{props.phoneNumber}</NavLink>
        </td>
      </tr>
      <tr  >
        <td className='t1 p-3'>Email:</td>
        <td className='t2 p-3 ps-0'>
          <span className='p-2'>
         <EmailIcon
          size={20}
          />
          </span>
          <NavLink to={`mailto:${props.email}`} >{props.email}</NavLink>
          </td>
      </tr>
      <tr  >
        <td className='t1 p-3'>Address:</td>
        <td className='t2 p-3 ps-0' style={{display:"flex"}}>
          <div className='p-2' style={{width:35}}>
          <AddressIcon
          size={23}
          />
          </div>
          <a target='_blank' href={`https://map.google.com?q=${props.officeAddres}`} rel="noreferrer" >{props.officeAddres}</a>
          </td>
      </tr>
    </table>
   
  </div>
}

