import React from "react";
import { NavLink } from "react-router-dom";
import { ROUTES } from "../../includes/constants";

const AdminHeader = ()=>{
    return <div className="d-flex pe-5" >
    <NavLink to={ROUTES.Login} className="btn blueBtn me-2" type="submit">Sign In</NavLink>
    <NavLink to={ROUTES.SignUp} className="btn blueBtn" type="submit">Sign Up</NavLink>
    </div>
}
export default AdminHeader;