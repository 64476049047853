/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-empty */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useRef, useState } from "react";
import { CheckBoxButton, InputField, SelectField, SubmitButton, TextAreaField } from "../forms/inputField";
import { Formik} from "formik";
import * as y from "yup";
import { PostData, ReturnComma } from "../../includes/function";
import Country from "../../includes/utils/states.json";
import { ReactPhotoEditor } from 'react-photo-editor'
import { AddressProps, CountryProps, ResourcesProps, SubItemModel, SubscriptionProps } from "../../includes/types";
import { BaseLoader } from "../../css/spinnner";
import { AddImage, TrashIcon } from "../../assets/icons";
import { AddressIcon, EditIcon } from "../icons";
import SubscriptionPlans from "../subscription_plans";
import AmenitiesComponent from "./Amenities";
import Moment from "moment";
import axios,{AxiosError,AxiosResponse} from "axios";

const schema = y.object({
        title:y.string().required("Title is required."),
        description:y.string().required("Description is required."),
        price:y.string().required("Price is required."),
        type:y.string().required("Type is required."),
        address:y.string().required("Address is required."),
        purpose:y.string().required("Purpose is required."),
        sub_type:y.string().required("Sub type is required."),
        land_mark:y.string().required("Land mark is required."),
        state:y.string().required("State is required."),
        city:y.string().required("City is required."),
})
interface NewLandListingProps {
    onClose:()=>void;
}
// import 'react-photo-editor/dist/style.css'
export const NewLandListing = (props:NewLandListingProps)=>{
  const [AddressList,setAddressList] = useState<AddressProps[]>([
      ]) 
  const [subscription,setSubscription] = useState<SubscriptionProps>()
  const [showImages,setShowImages] = useState<boolean>(false);
  const [loadingSec,setLoadingSec] = useState<string>("");
  const [loading,setLoading] = useState<boolean>(false);
    const [checkPlan,setCheckPlan] = useState<boolean>(false);
    const [showPayment,setShowPayment] = useState<boolean>(false);
    const [showSuccess,setShowSuccess] = useState<boolean>(false);
    const [showEditor,setShowEditor] = useState<boolean>(false);
    const [file, setFile] = useState<File | undefined>()
    const [images, setImages] = useState<ResourcesProps[]>([]);
    const [imageURLS, setImageURLs] = useState(["","","","","","","","",""]);
    const [showAddress, setShowAddress] = useState<boolean>(false);
    const [Progress,setProgress] = useState<number>(0);
    const [sessionId,setSessionId] = useState<string>("");

const GetUserPlan = ()=>{
  setCheckPlan(true);
  PostData("get_subscription",{},false).then((res)=>{
  setCheckPlan(false);
  if(res.status)
  {
  
  }
  })
}
const UploadImage = (file:FileList,index:number)=>{
setProgress(0);
setUploadCompleted(false)
const formData = new FormData();
const token:string | null = localStorage.getItem("token");
formData.append("singleFile", file[0]);
formData.append("sessionId", sessionId);
formData.append("imageIndex", String(index));
axios.post(String(process.env.APIBaseUrl+"single_file_upload"),formData,{
headers:{
  "content-type":"multipart/form-data",
  "token":token,
  "subfolder":"temp"
},
  onUploadProgress:(e)=> {
  if(e.total)
  {
  const percentComplete = Math.round(100 * e.loaded) / e.total;
  setProgress(percentComplete);
  }
},
}).then(({data}:AxiosResponse)=>{
  images[index] = data.result;
  setImages(images);
  console.log(images);
  setTimeout(()=>{
    setUploadCompleted(true)
    setProgress(0);
  },1500)
}).catch((error:AxiosError)=>{
  console.log(error);
  setTimeout(()=>{
    setUploadCompleted(true)
    setProgress(0);
  },1500)
})

}
const [fetchingAddress,setFetchingAddress] = useState<boolean>(false);
const thisNewListing = useRef<HTMLDivElement>(null)
const [addressType,setAddressType] = useState<"address"|"bus stop"|"land mark">("address")
const [listOfTypes,setListOfType] = useState<{name:string;value:string}[]>([])
const [listOfSubTypes,setListOfSubType] = useState<SubItemModel[]>([])
const [allState,setAllStates] = useState<CountryProps[]>([]);
const [allCities,setAllCities] = useState<any[]>([]);
const thisForm = useRef<any>();
const [uploadCompleted,setUploadCompleted] = useState(true)
const [selectedIndex,setSelectedIndex] = useState<number | null>(null);
const GetSettings = ()=>{
        setLoading(true);
        PostData("get_settings",{},false).then((res)=>{
          setLoading(false);
        if(res.status)
        {
          setListOfType(res.result.types)
          setListOfSubType(res.result.types[0].sub)
        }
        })
} 
useEffect(() => {
 GetSettings();
 GetUserPlan();
 setSessionId(Moment().format("hhmmss"));
 const cn:CountryProps[] = Country as CountryProps[];
 setAllStates(cn);
 return ()=>{
  if(images.length !== 0)
  {
    PostData("delete_all_temp_files",{},false);
  }
 }
},[]);
const DeleteFile = (index:number)=>{
  if(images[index].uri)
  {
  const fileInfo = String(images[index].uri);
  PostData("delete_file",{
    fileName:fileInfo
  },false);
  }
}
const thisFileInput = useRef<HTMLInputElement>(null);
 return <div ></div>
return <Formik 
    initialValues={{
      title:"",
      description:"",
      type:"",
      thumbnail:"",
      images:[],
      country:"Nigeria",
      state:"",
      city:"",
      price:"",
      address:"",
      land_mark_latitude:"",
      land_mark_longitude:"",
      address_latitude:"",
      address_longitude:"",
      addressPlaceHolder:"",
      purpose:"",
      sub_type:"",
      numberOfBeds:"1",
      size:"",
      numberOfBathrooms:"1",
      numberOfToilets:"1",
      furnished:false,
      serviced:false,
      newly_build:false,
      land_mark:"",
      payment_duration:"Daily",
      installment:false,
      amenities:[],
      promote:false
    }}
    onSubmit={(values)=>{
    if(showImages)
    {
      if(images.length !== 0)
      {
      setLoading(true);
      const v:any = values;
      delete v?.addressPlaceHolder;
      PostData("property_listing",{
        ...v,
        images:JSON.stringify(images),
        amenities:JSON.stringify(values.amenities),
        thumbnail:images[0].uri
      }).then((res)=>{
        setLoading(false);
        if(res.status)
        {
          props.onClose()
        }
      })
      }else{
        alert("Oops! aleast one image is reqiured.")
      }
    }else{
      setShowImages(true)
    }
    }}
    validationSchema={schema}
    innerRef={thisForm}
    >
{({values,touched,isValid,errors,setFieldTouched,setFieldError,handleChange,setFieldValue,handleSubmit})=>{
const imagesList:ResourcesProps[] = values.images as ResourcesProps[];
return <div className="modal" ref={thisNewListing}  tabIndex={-1} >
<div className="modal-dialog modal-lg modal-dialog-scrollable" >
<div className="modal-content" style={{marginTop:showSuccess?120:50}}>
<div className="modal-header" >
    <h5 className="modal-title">{showImages && <SubmitButton 
left
title="Back"
    submit={()=>{
     setShowImages(false);  
}} />}{!showSuccess?showImages?"Add images":"Post a property":""}</h5>
    <button onClick={props.onClose} type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button> 
</div>
<div >
<div className="row">
  <div className="col-12" >
  <div className="progress">
  {Progress !== 0?<div className="progress-bar progress-bar-striped bg-success" role="progressbar" style={{width:`${Progress}%`}} aria-valuenow={45} aria-valuemin={0} aria-valuemax={100}></div>:null}
</div>
  </div>
</div> 
<div className="row">
<div className="col-12 px-2" >
{!uploadCompleted?<small className="ps-3">Uploading...</small>:null}
</div>
</div>
</div>
<div className="modal-body p-5" >
<div className="ImageEditorContainer">
{showSuccess?<div style={{height:180}}>
</div>:showImages?<div 
>
{checkPlan?<div>
<div className="alert alert-success p-3" >
  <div className="row" >
  <div className="col-2" >
<BaseLoader />
</div>
<div className="col-10" >
  Wait while we confirm your subscription
</div>
</div>
</div>
</div>:<div >
<div 
className=""
>
<div className="alert alert-success mt-3 mb-3 p-1" >
<div className="row px-2" >
<div
className="col-12 p-1 " >
<div className="card m-0 btn fileInputWrapper" style={{height:210,width:"100%",display:"flex",justifyContent:"center",alignItems:"center"}}>
{values.images.length !== 0 ?<div style={{width:"100%",height:"100%"}}>
<img 
src={imagesList[0].blob}
style={{width:"100%",height:"100%",objectFit:"cover"}}
/>
<span 
onClick={()=>{
  if(thisFileInput.current)
  {
  thisFileInput.current?.click();
  }
}}
style={{position:"absolute",display:"flex",justifyContent:"center",alignItems:"center",top:0,right:0,cursor:"pointer",zIndex:99999999,width:20,height:20,backgroundColor:"white"}}
>
  <EditIcon 
  size={15}
  />
  </span>
</div>:<span style={{position:"absolute"}}>Click here to select image</span>}
<input 
ref={thisFileInput}
type="file"
accept='image/*'
className="fileInput"
onChange={(e) => {
  const x:FileList | null = e.target.files;
  let imh:ResourcesProps[] | any[] = values.images;
  setSelectedIndex(0)
  if(x)
  {
    if(imh.length === 0)
    {
    imh = [{blob:URL.createObjectURL(x[0]),path:x}];
    setFieldValue("images",imh);
    UploadImage(x,0);
    }else{
    imh[0] = {blob:URL.createObjectURL(x[0]),path:x};
    setFieldValue("images",imh);
    UploadImage(x,0);
    }
  }
}} 
/>
</div>
</div>
</div>
<div className="row px-2" >
{values.images.filter((a,i)=>i !== 0).map((a,i,self)=>{
const imag:ResourcesProps = a as ResourcesProps | any
return <div
key={i} className="col-3 p-1 " >
<div className="card thumbImage m-0 btn fileInputWrapper">
{imag.blob && <img 
src={imag.blob}
style={{width:"100%",height:"100%",position:"absolute",top:0,left:0,objectFit:"cover"}}
/>}

{imag.blob && <span 
onClick={()=>{
 const x = self.filter((a,l)=>l !== i);
 setFieldValue("images",[imagesList[0],...x]);
 DeleteFile(i+1);
}}
style={{position:"absolute",display:"flex",justifyContent:"center",alignItems:"center",top:0,right:0,cursor:"pointer",zIndex:99999999,width:20,height:20,backgroundColor:"white"}}
>
  <TrashIcon 
  size={15}
  />
  </span>}
</div>
</div>})}
{values.images.length >= 1 && values.images.length < 5?<div
className="col-3 p-1 " >
<div className="card d-flex thumbImage m-0 btn fileInputWrapper justify-content-center align-items-center">
<span 
className="blueText"
style={{fontSize:12}}
>
  <AddImage 
  color=""
  />
  <span className="ps-3">Add More{(5 - values.images.length) > 1?"s":""} ({(5 - values.images.length)})</span>
</span>
<input 
type="file"
accept='image/*'
className="fileInput"
onChange={(e) => {
  const x:FileList | null = e.target.files;
  let imh:ResourcesProps[] | any[] = values.images;
  if(x)
  {
    imh = [...imh,{blob:URL.createObjectURL(x[0]),path:x}]
    setFieldValue("images",imh);
    UploadImage(x,imh.length -1);
  }
   
}} 
/>

</div>
</div>:null}
</div> 
{/* {!subscription?.title?<div style={{position:"absolute",width:"100%",height:"100%",top:0,left:0,backgroundColor:"rgba(255,255,255,0.3)",zIndex:9999999999999}} ></div>:null} */}
</div> 
<div className="alert alert-primary p-2 lx" >
<CheckBoxButton
label="Post as a Promote Ads"
name="promote"
value={values.promote?1:0}
description="To promote this listing select this option"
onValue={(d)=>{
    
}}
/>
</div> 
</div>
<div className="alert alert-success my-3" >
{subscription?.name?<div className="row" >
<div className="col-2" >
{subscription?.name}
</div>
<div className="col-10" >
  <div><b>{subscription?.name}Mskks</b></div>
  <div><small>{subscription?.description} this slldld </small></div>
  <p><small ><b>Expiry:</b>{subscription?.duration}</small>
  <SubmitButton 
  submit={()=>{}}
  title="Upgrade Plan"
  />
  </p>
</div>
</div>:<div className="row" >
<div className="col-12" >
  <div><small>You do not subscribe to any plan yet, click on the button below to get started.</small><SubmitButton 
  submit={()=>{
    setShowPayment(true)
  }}
  title="Subscribe now"
  /></div>
  
</div>
</div>}
</div>
</div>}
</div>:
<div>
<InputField 
max={500}
label="Title"
value={values.title}
onValueChange={handleChange("title")}
name="title"
placeholder="e.g (my home)"
type="text"
required
error={touched.title && errors.title}
/>
<TextAreaField 
max={2000}
richtext={false}
label="Description"
value={values.description}
onValueChange={(d:any)=>{
  if(typeof d === "string")
  {
  if(d === "<p><br></p>")
  {
    d = "";
  }
  setFieldValue("description",d)
}else{
  setFieldValue("description",d.target.value)
}
}}
name="description"
placeholder="e.g my beautify home"
required
error={touched.description && errors.description}
/>
<div className="row" >
<div className="col-6" >
<InputField 
max={500}
label="Price"
value={ReturnComma(values.price)}
onValueChange={handleChange("price")}
name="price"
placeholder="1,000"
type="text"
leftIcon={"₦"}
error={touched.price && errors.price}
/>
</div>
<div className="col-6" >
  <SelectField 
  label="Installmental payment"
  placeholder="Select Installmental payment"
  value={values.installment?"Yes":"No"}
  onValueChange={(d)=>{
    setFieldValue("installment",d.value === "1")
  }}
  error={touched.installment && errors.installment}
  options={[
    {name:"No",value:"0"},
    {name:"Yes",value:"1"}
]}
  />  
</div>

</div>
<div className="row" >
<div className="col-6" >
  <SelectField 
  label="Type"
  placeholder="Select type"
  value={values.type}
  onValueChange={(d)=>{
    setFieldValue("type",d.value)
    if(d.sub)
    {
    setFieldValue("sub_type","")
    setListOfSubType(d.sub)
    }
  }}
  error={touched.type && errors.type}
  options={listOfTypes.map((a,i)=>{
    return  a;
  })}
  />  
</div>
<div className="col-6" >
  <SelectField 
  label="Sub type"
  placeholder="Select sub type"
  value={values.sub_type}
  onValueChange={(d)=>{
    // alert(JSON.stringify(d))
    setFieldValue("sub_type",d.value)
  }}
  error={touched.sub_type && errors.sub_type}
  options={listOfSubTypes.map((a,i)=>{
    return  a;
  })}
  />  
</div>
</div>
<div className="row" >
<div className="col-6" >
  <SelectField 
  label="Number of bathrooms"
  value={values.numberOfBathrooms}
  onValueChange={(d)=>{
    // alert(JSON.stringify(d))
    setFieldValue("numberOfBathrooms",d.value)
  }}
  options={Array.from({length:10}).map((a,i)=>{
    return {
      name:String(i+1),value:String(i+1)
    }
  })}
  />  
</div>
<div className="col-6" >
  <SelectField 
  label="Number of beds"
  value={values.numberOfBeds}
  onValueChange={(d)=>{
    // alert(JSON.stringify(d))
    setFieldValue("numberOfBeds",d.value)
  }}
  options={Array.from({length:10}).map((a,i)=>{
    return {
      name:String(i+1),value:String(i+1)
    }
  })}
  />  
</div>

</div>
<div className="row" >
<div className="col-6" >
  <SelectField 
  label="Number of toilets"
  value={values.numberOfToilets}
  onValueChange={(d)=>{
    // alert(JSON.stringify(d))
    setFieldValue("numberOfToilets",d.value)
  }}
  options={Array.from({length:10}).map((a,i)=>{
    return {
      name:String(i+1),value:String(i+1)
    }
  })}
  />  
</div>
<div className="col-6" >
  <SelectField 
  label="Payment duration"
  value={values.payment_duration}
  onValueChange={(d)=>{
    // alert(JSON.stringify(d))
    setFieldValue("payment_duration",d.value)
  }}
  options={["Daily","Weekly","Monthly","Yearly"].map((a,i)=>{
    return {
      name:a,value:a
    }
  })}
  error={touched.payment_duration && errors.payment_duration}
  />  
</div>

</div>
<div className="alert alert-success" >
<div className="row" >
<div className="col-6" >
  <SelectField 
  label="Location (State)"
  placeholder="Select state"
  value={values.state}
  onValueChange={(d)=>{
    setFieldValue("state",d.value);
    if(d.cities)
    {
    setAllCities(d?.cities)
    setFieldValue("city","")
    }
  }}
  options={allState.map((a,i)=>{
    return {
      name:a.name,value:a.name,cities:a.cities
    }
  })}
  error={touched.state && errors.state}
  />  
</div>
<div className="col-6" >
  <SelectField 
  label="Location (City)"
  value={values.city}
  placeholder="Select a city"
  onValueChange={(d)=>{
    // alert(JSON.stringify(d))
    setFieldValue("city",d.value)
  }}
  options={allCities.map((a,i)=>{
    return {
      name:a,value:a
    }
  })}
  error={touched.city && errors.city}
  />  
</div>
</div><label className="form-label" >Address</label>
<div 
onClick={()=>{
  if(values.state === "" || values.city === "" )
  {
    thisNewListing.current?.scroll({top:400,behavior:"smooth"});
    thisForm.current.handleSubmit()
    // alert("State is required");
    return ;
  } 
  
  setShowAddress(true)
  setAddressType("address")
  setFieldValue("land_mark","");
  thisNewListing.current?.scroll({top:0,behavior:"smooth"});
}}
className="roundInput row left-icon-wrap"
style={{marginTop:5,cursor:"pointer"}}
>
<div className="left-icon col-2">
  <AddressIcon 
  size={20}
  />
</div>
<div className="col-10" >
 {values.address === ""?"e.g (12 golden streeet)":values.address}
 {loadingSec === "address"?<span 
  style={{position:"absolute",right:30}}
  ><BaseLoader
  size={"small"}
  /></span>:null}
  </div>
</div>
{touched.address && errors.address?<div className="error">{errors.address}</div>:null}
<label className="form-label mt-3" >Nearby Land mark</label>
<div 
onClick={()=>{
  if(values.state === "" || values.city === "" || values.address === "")
  {
    thisNewListing.current?.scroll({top:400,behavior:"smooth"});
    thisForm.current.handleSubmit()
    // alert("State is required");
    return ;
  } 
  
  setAddressType("land mark")
  setShowAddress(true)
}}
className="roundInput row left-icon-wrap"
style={{marginTop:5,cursor:"pointer"}}
>
<div className="left-icon col-2">
  <AddressIcon 
  size={20}
  />
    </div>
  <div className="col-10" >
 {values.land_mark === ""?"e.g (Shopping Mall)":values.land_mark}
 {loadingSec === "land mark"?<span 
  style={{position:"absolute",right:30}}
  ><BaseLoader
  size={"small"}
  /></span>:null}
  </div>
</div>
{touched.land_mark && errors.land_mark?<div className="error">{errors.land_mark}</div>:null}
</div>
<div className="alert alert-success" >
<div className="row" >
<div className="col-4" >
  <SelectField 
  label="Furnished"
  value={values.furnished?"Yes":"No"}
  onValueChange={(d)=>{
    // alert(JSON.stringify(d))
    setFieldValue("furnished",d.value === "1")
  }}
  options={[
    {name:"No",value:"0"},
    {name:"Yes",value:"1"}
  ]}
  />  
</div>
<div className="col-4" >
  <SelectField 
  label="Serviced"
  value={values.serviced?"Yes":"No"}
  onValueChange={(d)=>{
    // alert(JSON.stringify(d))
    setFieldValue("serviced",d.value === "1")
  }}
  options={[
    {name:"No",value:"0"},
    {name:"Yes",value:"1"}
  ]}
  />  
</div>
<div className="col-4" >
<SelectField 
  label="Newly build"
  value={values.newly_build?"Yes":"No"}
  onValueChange={(d)=>{
    // alert(JSON.stringify(d))
    setFieldValue("newly_build",d.value === "1")
  }}
  options={[
    {name:"No",value:"0"},
    {name:"Yes",value:"1"}
  ]}
  /> 
</div>
</div> 
<div className="row" >
<div className="col-6" >
<InputField 
max={500}
label="Size (Optional)"
value={ReturnComma(values.size)}
onValueChange={handleChange("size")}
name="size"
placeholder="1,000"
type="text"
leftIcon={"KM"}
error={touched.size && errors.size}
/> 
</div>
<div className="col-6" >
<SelectField 
  label="Purpose"
  value={values.purpose}
  onValueChange={(d)=>{
    // alert(JSON.stringify(d))
    setFieldValue("purpose",d.name)
  }}
  options={[
    {name:"For Rent",value:"Rent"},
    {name:"For Sale",value:"Sale"},
    {name:"For Short let",value:"Short_let"}
  ]}
  /> 
</div>
</div>
</div>
<div className="alert alert-success" >
  <label >Amenities</label>
<AmenitiesComponent 
onValue={(d)=>{
  setFieldValue("amenities",d);
}}
/>
</div>
</div>}
<ReactPhotoEditor
        open={showEditor}
        onClose={()=>setShowEditor(false)}
        file={file}
        onSaveImage={(d)=>{
        }}
      />
</div>

</div>
{showAddress && <div className="d-flex justify-content-center pt-5"  style={{position:"absolute",top:0,width:"100%",height:"100%",backgroundColor:"rgba(0,0,0,0.4)"}} >
<div className="card" style={{width:"90%",height:200}} >
<div 
className="px-3 pt-3"
>
    <SubmitButton 
    left
    title="Back"
    submit={()=>{
     setShowAddress(false); 
     setAddressList([])
    }} />
</div>
<div className="p-3 position-relative"  >
<InputField 
rightIcon={fetchingAddress?<span style={{position: "absolute",
  right: 2,
  top: -8}}><BaseLoader size="small" /></span>:""}
max={500}
label={`Search ${addressType} in ${values.city},${values.state}, Nigeria`}
value={values.addressPlaceHolder}
onValueChange={(d)=>{
  setFieldValue("addressPlaceHolder",d.target.value)
if(d.target.value === "" || String(d.target.value).length < 3)
{
  return ;
}

setFetchingAddress(true)
setAddressList([])
const params = addressType === "address"?`${String(d.target.value).replace(values.city,"").replace(values.state,"")}`:`${d.target.value}`
PostData("getaddress_by_string",{
  searchText:String(params).toLowerCase()+` ${values.state === "FCT"?"abuja":values.state} Nigeria`
},false).then((res)=>{
  setFetchingAddress(false)
  if(res.status)
  {
    setAddressList(res.result)
  }
})
}}
name="addressPlaceHolder"
placeholder="Type here..."
type="text"
/>
<div className="position-relative" >
<ul className="dropdown-container" style={{left:10,top:-8,width:"80%"}} >
{AddressList.map((item,i)=><li 
    key={i} >
    <div className=""
    onClick={()=>{
      if(addressType === "address")
      {
     setFieldValue("address",item.address);
    }else{
      setFieldValue("land_mark",item.address);
     }
     setLoadingSec(addressType);
  PostData("getaddress_latlng",{
    searchText:item.address
  },false).then((res)=>{
    setLoadingSec("");
    if(!res.status)
    {
      setFieldValue("address","");
      setFieldValue("land_mark","");
      alert("Address not found, try another one.")
    }else{
      if(addressType === "land mark")
      {
      setFieldValue("land_mark_latitude",res.result.latitude);
      setFieldValue("land_mark_longitude",res.result.longitude);
      }else{
      setFieldValue("address_latitude",res.result.latitude);
      setFieldValue("address_longitude",res.result.longitude);
      }
    }
  })
     setShowAddress(false);
     setAddressList([]);
     setFieldValue("addressPlaceHolder","")
    }}
    >{item.full_address}</div>
    </li>)}
</ul>
</div>
</div>
</div>
</div>}
<div className="modal-footer">
<SubmitButton 
loading={loading}
    title={showImages?"Submit":"Next"}
    submit={handleSubmit}
    />
</div>
 </div>
  </div>
  {showPayment && <SubscriptionPlans 
  onClose={()=>{
    setShowPayment(false)
  }}
  />} 
  {/* <ImageEditor 
  uri={"../bg-home-01.jpg"}
  /> */}
  </div>}}
</Formik>
}

