/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable @typescript-eslint/no-empty-function */
import React, { useEffect, useState } from 'react';
import {NavLink,useLocation} from 'react-router-dom';
import BaseLogo  from '../../images/luxzry-pro-logo.png';
import { AppName, LOCALSTORAGE, ROUTES, TabLinks } from '../../includes/constants';
import UserHeader from './user';
import AdminHeader from './admin';
import { NewListing } from '../newlisting';
import { loginBtnRef } from '../../App';

export function Header() {
const location = useLocation();
const [showNewListing,setShowNewListing] = useState<boolean>(false)
useEffect(()=>{
  
},[])
return (<nav className="navbar navbar-expand-lg bg-body-tertiary navbar-fixed ">
<div className="container-fluid" >
  <NavLink className="navbar-brand ms-5" to={"/"}>
  <img src={BaseLogo} 
  style={{width:39}}
  />
  <span className='appName'>{AppName}</span>
  </NavLink>
  <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
    <span className="navbar-toggler-icon"></span>
  </button>
  <div className="collapse navbar-collapse" >
    <ul className="navbar-nav me-auto mb-2 mb-lg-0 main-nav" >
      {TabLinks.map((a,i)=><li key={i} className={`nav-item ${a.submenu?"dropdown":""}`}>
      <NavLink className={`nav-link ${a.submenu?"dropdown-toggle":""}`} aria-current="page" to={String(a.route)} role="button" data-bs-toggle={a.submenu?`dropdown"`:""}  aria-expanded="false">{a.title}</NavLink>
      </li>)}
    </ul>
   
    {localStorage.getItem(LOCALSTORAGE.UserData)?<UserHeader 
    newPost={()=>{
      setShowNewListing(true)
    }}
    />:location.pathname === ROUTES.UserDashboard?<UserHeader
    newPost={()=>{
      setShowNewListing(true)
    }}
    />:location.pathname === ROUTES.AdminDashboard?<AdminHeader />:<div className="d-flex pe-5" >
    <NavLink ref={loginBtnRef} to={ROUTES.Login} className="btn blueBtn me-2" type="submit">Sign In</NavLink>
    <NavLink to={ROUTES.SignUp} className="btn blueBtn" type="submit">Sign Up</NavLink>
    </div>}
  </div>
</div>
{showNewListing && <NewListing 
onClose={()=>{
  setShowNewListing(false)
}}
/>}
</nav>)
}