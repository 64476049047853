/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect,  useState } from "react";
import { useLocation } from "react-router-dom";
import {CChart,CChartBar,CChartPie,CChartLine,CChartPolarArea} from '@coreui/react-chartjs'
import {CRow,CCard,CCardTitle,CCardBody,CTableBody,CTableDataCell,CTableRow,CTableHeaderCell,CTable,CTableHead,CCallout,CWidgetStatsB,CWidgetStatsC,CWidgetStatsD,CDropdownItem,CDropdownMenu,CCol,CWidgetStatsA,CDropdown,CDropdownToggle} from "@coreui/react"
import { VerticalDots } from "../../../components/icons";
import { PostData, ReturnComma } from "../../../includes/function";
import { ItemProps, TransactionHistoryProps } from "../../../includes/types";
import Moment from "moment";
const DashboardAnalytics = ()=>{
const location = useLocation();
const data = {
  labels: [
    'Red',
    'Green',
    'Yellow',
    'Grey',
    'Blue'
  ],
  datasets: [{
    label: 'My First Dataset',
    data: [11, 16, 7, 3, 14],
    backgroundColor: [
      'rgb(255, 99, 132)',
      'rgb(75, 192, 192)',
      'rgb(255, 205, 86)',
      'rgb(201, 203, 207)',
      'rgb(54, 162, 235)'
    ]
  }]
};
const [loading,setLoading] = useState<boolean>(false)
const [limit,setLimit] = useState<number>(8)
const [list,setList] = useState<TransactionHistoryProps[]>([])

const GetHistory =()=>{
  PostData("transaction_history",{
    limit:limit
  },false).then((res)=>{
  if(res.status)
  {
  setList(res.result)
  }
  })
}
useEffect(()=>{
GetHistory()
},[])
return <div className="dashboard">
<CRow>
<CCol sm={7}>
<CCard style={{ width: '100%',height:'100%' }}>
<CChart
  type="line" 
  data={{
    labels: ["January", "February", "March", "April", "May", "June", "July"],
    datasets: [
      {
        label: "My First dataset",
        backgroundColor: "rgba(220, 220, 220, 0.2)",
        borderColor: "rgba(220, 220, 220, 1)",
        pointBackgroundColor: "rgba(220, 220, 220, 1)",
        pointBorderColor: "#fff",
        data: [40, 20, 12, 39, 10, 40, 39, 80, 40]
      },
      {
        label: "My Second dataset",
        backgroundColor: "rgba(151, 187, 205, 0.2)",
        borderColor: "rgba(151, 187, 205, 1)",
        pointBackgroundColor: "rgba(151, 187, 205, 1)",
        pointBorderColor: "#fff",
        data: [50, 12, 28, 29, 7, 25, 12, 70, 60]
      },
    ],
  }}
  options={{
    plugins: {
      legend: {
        labels: {
          color:"blue",
        }
      }
    },
    scales: {
      x: {
        grid: {
          color:"yellow",
        },
        ticks: {
          color:"red",
        },
      },
      y: {
        grid: {
          color:"black",
        },
        ticks: {
          color:"yellow",
        },
      },
    },
  }}
/>
</CCard>
 </CCol>
 <CCol sm={5}>
<CRow >
  <CCol sm={12}>
  <CWidgetStatsA
      className="mb-4 white-text"
      color="primary"
      value={
        <>
          Homes
          <span className="fs-6 fw-normal ps-2">
            (0)
          </span>
        </>
      }
      title="Total numbers of homes"
      action={
        <CDropdown alignment="end">
          <CDropdownToggle color="transparent" caret={false} className="p-0">
           <VerticalDots
           color="white"
           size={40}
           />
          </CDropdownToggle>
          <CDropdownMenu>
            <CDropdownItem>Goto list</CDropdownItem>
          </CDropdownMenu>
        </CDropdown>
      }
      chart={
        <CChartLine
          className="mt-3 mx-3"
          style={{ height: '70px' }}
          data={{
            labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July'],
            datasets: [
              {
                label: 'My homes',
                backgroundColor: 'transparent',
                borderColor: 'rgba(255,255,255,.55)',
                pointBackgroundColor: '#321fdb',
                data: [10, 10, 310, 510, 310, 140, 40],
              },
            ],
          }}
          options={{
            plugins: {
              legend: {
                display: false,
              },
            },
            maintainAspectRatio: false,
            scales: {
              x: {
                grid: {
                  display: false,
                  drawBorder: false,
                },
                ticks: {
                  display: false,
                },
              },
              y: {
                min: 30,
                max: 89,
                display: false,
                grid: {
                  display: false,
                },
                ticks: {
                  display: false,
                },
              },
            },
            elements: {
              line: {
                borderWidth: 1,
                tension: 0.4,
              },
              point: {
                radius: 4,
                hitRadius: 10,
                hoverRadius: 4,
              },
            },
          }}
        />
      }
    />
  </CCol>
  <CCol sm={12}>
  <CWidgetStatsA
      className="mb-4 white-text"
      color="primary"
      value={
        <>
          Homes
          <span className="fs-6 fw-normal ps-2">
            (0)
          </span>
        </>
      }
      title="Total numbers of homes"
      action={
        <CDropdown alignment="end">
          <CDropdownToggle color="transparent" caret={false} className="p-0">
           <VerticalDots
           color="white"
           size={40}
           />
          </CDropdownToggle>
          <CDropdownMenu>
            <CDropdownItem>Goto list</CDropdownItem>
          </CDropdownMenu>
        </CDropdown>
      }
      chart={
        <CChartLine
          className="mt-3 mx-3"
          style={{ height: '70px' }}
          data={{
            labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July'],
            datasets: [
              {
                label: 'My homes',
                backgroundColor: 'transparent',
                borderColor: 'rgba(255,255,255,.55)',
                pointBackgroundColor: '#321fdb',
                data: [10, 10, 310, 510, 310, 140, 40],
              },
            ],
          }}
          options={{
            plugins: {
              legend: {
                display: false,
              },
            },
            maintainAspectRatio: false,
            scales: {
              x: {
                grid: {
                  display: false,
                  drawBorder: false,
                },
                ticks: {
                  display: false,
                },
              },
              y: {
                min: 30,
                max: 89,
                display: false,
                grid: {
                  display: false,
                },
                ticks: {
                  display: false,
                },
              },
            },
            elements: {
              line: {
                borderWidth: 1,
                tension: 0.4,
              },
              point: {
                radius: 4,
                hitRadius: 10,
                hoverRadius: 4,
              },
            },
          }}
        />
      }
    />
  </CCol>
</CRow>
 </CCol>
</CRow>
</div>
}
export default DashboardAnalytics;